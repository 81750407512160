/* eslint-disable max-lines */

import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Radio,
  RadioGroup,
} from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  BackButton,
  Business,
  ButtonWithLoader,
  CurrencyTextField,
  CustomFieldValidatorState,
  Nil,
  NumberUtils,
  PuiTextArea,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
} from '@pbt/pbt-ui-components'
import { findById } from '@pbt/pbt-ui-components/src/utils'

import { RefundInvoice } from '~/api/graphql/generated/types'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import MenuDropdown from '~/components/common/inputs/MenuDropdown'
import AlertLabel from '~/components/common/labels/AlertLabel'
import { paymentNotesMaxLength } from '~/components/dashboard/invoices/payment/payment-details-dialog/PaymentDetailsChunk'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import {
  ADJUSTMENT_TYPES,
  PaymentTypes,
  RefundPaymentMethods,
} from '~/constants/financeConstants'
import PaymentType, { PaymentAdjustment } from '~/constants/paymentTypes'
import { RhapsodyGoPaymentMethod } from '~/constants/RhapsodyGoPaymentMethod'
import { VOIDED } from '~/constants/rhapsodyGoPaymentsStates'
import { FAILED } from '~/constants/transactionStatus'
import {
  clearTransactionInfo,
  createPayment,
  createPaymentTransaction,
  createRefundOnAccount,
  findOnAccountOriginPayment,
  refundRhapsodyGoStripeTransaction,
  refundRhapsodyGoTransaction,
  voidRhapsodyGoStripeTransaction,
  voidRhapsodyGoTransaction,
} from '~/store/actions/payments'
import { getRefundInvoice } from '~/store/duck/refunds'
import {
  fetchRhapsodyPayConfig,
  getRhapsodyPayConfig,
} from '~/store/duck/rhapsodyPay'
import {
  getCurrentBusiness,
  getCurrentBusinessIsOmniChannel,
  getCurrentUser,
  getCurrentUserId,
} from '~/store/reducers/auth'
import {
  getFeatureToggle,
  getPaymentMethod,
  getPaymentType,
} from '~/store/reducers/constants'
import { getFinanceInvoice } from '~/store/reducers/finance'
import {
  getOnAccountOriginPayment,
  getPaymentsIsLoading,
  getRhapsodyGoProcessedPayment,
} from '~/store/reducers/payments'
import { ExtendPayment, RhapsodyPayConfig, UnsavedExtendPayment } from '~/types'
import { handleNumberInput } from '~/utils'
import {
  getPaymentInvoiceIds,
  isRhapsodyGoAvailableForPractice,
} from '~/utils/paymentUtils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import FeatureToggle from '../../../../constants/featureToggle'
import { POSPaymentFailureAlertDialog } from '../POSPaymentFailureAlertDialog'
import { OtherPaymentMethodButton } from './buttons/OtherPaymentMethodButton'
import { SubmitPosPaymentOrRecordManuallyButton } from './buttons/SubmitPosPaymentOrRecordManuallyButton'
import { MorePaymentOptionsMenu } from './MorePaymentOptionsMenu'
import PaymentClientNotesSection from './PaymentClientNotesSection'
import { PaymentDepositRefundPaymentSelection } from './PaymentDepositRefundPaymentSelection'
import { PaymentInvoiceRefundPaymentSelection } from './PaymentInvoiceRefundPaymentSelection'

const useStyles = makeStyles(
  (theme) => ({
    adjustPaymentInfo: {
      padding: theme.spacing(3, 3, 3, 3),
    },
    adjustmentNotesContainer: {
      marginTop: theme.spacing(1),
    },
    boldText: {
      fontWeight: 500,
    },
    button: {
      minWidth: 150,
      height: 40,
    },
    chargeLabel: {
      alignSelf: 'center',
    },
    currencyFieldRefund: {
      maxWidth: 100,
      paddingLeft: theme.spacing(1),
    },
    currencyFieldWrapper: {
      maxWidth: 120,
      margin: theme.spacing(0),
    },
    currencyTextField: {
      textAlign: 'right',
    },
    currencyTextFieldBold: {
      fontWeight: 500,
    },
    dropdownButton: {
      color: theme.colors.link,
    },
    footer: {
      padding: theme.spacing(2, 3),
      borderTop: theme.constants.tabBorder,
    },
    formControl: {
      marginBottom: theme.spacing(1),
    },
    formLabel: {
      color: theme.colors.primaryText,
      fontWeight: 500,
      fontSize: '1.4rem',
      marginLeft: 0,
    },
    infoIcon: {
      marginLeft: theme.spacing(0),
    },
    labelRoot: {
      margin: 0,
      height: 30,
    },
    largeButton: {
      minWidth: 150,
      height: 40,
      marginLeft: theme.spacing(2),
    },
    paymentInfo: {
      padding: theme.spacing(3, 12, 3, 3),
    },
    paymentNotesContainer: {
      marginTop: theme.spacing(2.5),
    },
    paymentNotesLabel: {
      margin: 0,
    },
    paymentTypes: {
      marginLeft: theme.spacing(2),
    },
    paymentTypesMenu: {
      width: 220,
    },
    radio: {
      padding: 0,
      marginRight: theme.spacing(1),
    },
    radioLabel: {
      fontSize: '1.6rem',
      color: theme.colors.secondaryText,
    },
    refundCheckNumber: {
      maxWidth: 100,
      paddingLeft: theme.spacing(1),
    },
    tooltipContainer: {
      margin: theme.spacing(0.5, 0, 0, 0.5),
    },
    totalChargeInput: {
      marginTop: theme.spacing(0.5),
    },
  }),
  { name: 'PaymentInitialization' },
)

export interface PaymentInitializationProps {
  adjustment: PaymentAdjustment
  assignedInvoiceId?: string
  clientId: string | Nil
  depositExceedsInvoiceAmountRefund: boolean
  estimateId: string
  invoiceAmount?: number
  invoiceIds: string[]
  isAdjustment: boolean
  isDepositRefund: boolean
  isInvoiceRefund: boolean
  isLoading: boolean
  isPayout: boolean
  isRefund: boolean
  isReverseGo: boolean
  isReversePayment: boolean
  isVoid: boolean
  onBack?: () => void
  onContinue?: any
  onContinueToBillingAddresses: (type?: string) => void
  onContinueToPaymentDetails: () => void
  onOk: () => void
  payment: ExtendPayment | Nil
  paymentAmount: number
  paymentType: PaymentTypes
  paymentTypeName?: string
  setPaymentType: React.Dispatch<PaymentTypes>
  updateAdjustment: React.Dispatch<React.SetStateAction<PaymentAdjustment>>
  updatePaymentAmount: React.Dispatch<number>
}

const getGoTxId = (payment: any, paymentTypeName: string) =>
  paymentTypeName === PaymentType.GO_PAYMENT ? payment?.id : payment?.goTxId

const getGoStripeTxId = (payment: any, paymentTypeName: string) =>
  paymentTypeName === PaymentType.GO_STRIPE_PAYMENT
    ? payment?.id
    : payment?.goStripeTxId

const PaymentInitialization = ({
  payment,
  adjustment,
  updateAdjustment,
  clientId,
  estimateId,
  paymentAmount,
  paymentType,
  setPaymentType,
  invoiceAmount = 0,
  updatePaymentAmount,
  isLoading,
  isPayout,
  isRefund,
  isDepositRefund,
  isReverseGo,
  isReversePayment,
  depositExceedsInvoiceAmountRefund,
  isInvoiceRefund,
  isAdjustment,
  isVoid,
  assignedInvoiceId,
  invoiceIds,
  onBack,
  onOk,
  paymentTypeName = 'Payment',
  onContinue,
  onContinueToPaymentDetails,
  onContinueToBillingAddresses,
}: PaymentInitializationProps) => {
  const classes = useStyles()
  const dispatch = useDispatch()
  const { t } = useTranslation(['Common', 'Errors', 'Invoices', 'Validations'])
  const PaymentMethod = useSelector(getPaymentMethod)
  const PaymentTypeConstant = useSelector(getPaymentType)
  const currentBusiness = useSelector(getCurrentBusiness) || ({} as Business)
  const currentUser = useSelector(getCurrentUser)
  const isIpoM0VoidAndPaymentReversalFTEnabled = useSelector(
    getFeatureToggle(FeatureToggle.IPO_M0_VOID_AND_PAYMENT_REVERSAL),
  )

  const is3pClinic = !useSelector(getCurrentBusinessIsOmniChannel)
  const refundInvoice =
    useSelector(getRefundInvoice(assignedInvoiceId)) || ({} as RefundInvoice)
  const estimate = useSelector(getFinanceInvoice(estimateId))
  const rhapsodyGoCompletedPayment = useSelector(getRhapsodyGoProcessedPayment)
  const rhapsodyPayInitialConfig =
    useSelector(getRhapsodyPayConfig(currentBusiness?.id)) ||
    ({} as RhapsodyPayConfig)
  const userId = useSelector(getCurrentUserId)
  const onAccountOriginPayment = useSelector(getOnAccountOriginPayment)

  const PAYMENT_AMOUNT_HINT = t(
    'Invoices:PAYMENTS.PAYMENT_INITIALIZATION.PAYMENT_AMOUNT_HINT',
  )

  const MAIN_PAYMENT_TYPES = [
    {
      paymentType: PaymentTypes.CREDIT_CARD,
      label: t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.CREDIT_DEBIT_TYPE'),
    },
    { paymentType: PaymentTypes.OTHER, label: t('Common:OTHER') },
  ]
  const moreOptionTitle = t(
    'Invoices:PAYMENTS.PAYMENT_INITIALIZATION.MORE_REFUND_OPTIONS',
  )

  const estimatePayments =
    estimate?.payments?.filter((p) => p.refundAvailable) ?? []
  const [estimatePaymentId, setEstimatePaymentId] = useState(
    estimatePayments.length === 1 ? estimatePayments[0].id : '',
  )
  const selectedEstimatePayment =
    findById(estimatePaymentId, estimatePayments) ?? {}

  const refundOriginalPayments = refundInvoice?.originalInvoice?.payments ?? []
  const eligibleRefundOriginalPayments = refundOriginalPayments.filter(
    (p) => p.refundableAmount > 0,
  )

  const [refundOriginalPaymentId, setRefundOriginalPaymentId] = useState(
    eligibleRefundOriginalPayments.length === 1
      ? eligibleRefundOriginalPayments[0].id
      : '',
  )
  const [transaction, setTransaction] = useState<Record<string, any>>({})
  const [refundOptionDropDownName, setRefundOptionDropDownName] =
    useState(moreOptionTitle)

  const refundOriginalPayment =
    findById(refundOriginalPaymentId, eligibleRefundOriginalPayments) ?? {}

  const refundOriginalPaymentTypeName = refundOriginalPayment.type?.name
  const refundOriginalPaymentMethodName = refundOriginalPayment.method?.name
  const onAccountOriginPaymentTypeName = (onAccountOriginPayment as any)?.type
    ?.name
  const onAccountOriginPaymentMethodName = onAccountOriginPayment?.method?.name
  const isReverse = isReverseGo || isReversePayment

  const goTxId = getGoTxId(
    isInvoiceRefund
      ? refundOriginalPayment
      : isDepositRefund
        ? selectedEstimatePayment
        : isPayout
          ? onAccountOriginPayment
          : payment,
    isInvoiceRefund
      ? refundOriginalPaymentTypeName
      : isDepositRefund
        ? selectedEstimatePayment
        : isPayout
          ? onAccountOriginPaymentTypeName
          : payment?.type,
  )
  const goStripeTxId = getGoStripeTxId(
    isInvoiceRefund
      ? refundOriginalPayment
      : isDepositRefund
        ? selectedEstimatePayment
        : isPayout
          ? onAccountOriginPayment
          : payment,
    isInvoiceRefund
      ? refundOriginalPaymentTypeName
      : isDepositRefund
        ? selectedEstimatePayment
        : isPayout
          ? onAccountOriginPaymentTypeName
          : payment?.type,
  )

  const { posPayEnabled, id: businessId } = currentBusiness
  const { serviceFee = 0 } = rhapsodyPayInitialConfig

  const isGoAvailableForPractice = isRhapsodyGoAvailableForPractice(
    currentBusiness,
    rhapsodyPayInitialConfig,
  )
  const setCloseAfterCreationOn = useCloseAfterCreation(
    onOk,
    getPaymentsIsLoading,
  )

  const adjustmentPaymentType = Utils.findConstantIdByName(
    'Adjustment',
    PaymentTypeConstant,
  )
  const adjustmentPaymentMethod = Utils.findConstantIdByName(
    'Other',
    PaymentMethod,
  )

  const refundPaymentMethodsToIdsMap = {
    [RefundPaymentMethods.CASH]: Utils.findConstantIdByName(
      'Cash',
      PaymentMethod,
    ),
    [RefundPaymentMethods.CARD]: Utils.findConstantIdByName(
      'Credit card',
      PaymentMethod,
    ),
    [RefundPaymentMethods.CHECK]: Utils.findConstantIdByName(
      'Check',
      PaymentMethod,
    ),
    [RefundPaymentMethods.ON_ACCOUNT]: Utils.findConstantIdByName(
      'On account',
      PaymentMethod,
    ),
  }

  const payoutButtonName = onAccountOriginPayment?.posTxId
    ? `${t('Common:PAYMENTS.PAYOUT')}: ${t('Invoices:PAYMENTS.PAYMENT_INITIALIZATION.SEND_TO_TERMINAL').toLowerCase()}`
    : goTxId || goStripeTxId
      ? `${t('Common:PAYMENTS.PAYOUT')}: ${t('Common:PAYMENTS.PAYMENT_CARD').toLowerCase()}`
      : `${t('Common:PAYMENTS.PAYOUT')}${onAccountOriginPayment?.method?.name ? `: ${onAccountOriginPayment?.method?.name?.toLowerCase()}` : ''}`

  const [openPaymentDialog] = useDialog(DialogNames.PAYMENT_DETAILS)
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)
  const [openConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.REFUND_INVOICE_PAYMENT,
  })
  const isOtherPaymentType = paymentType === PaymentTypes.OTHER
  const initialRefundValue = isOtherPaymentType
    ? payment?.amount
    : isPayout
      ? payment?.refundableAmount || payment?.unappliedAmount
      : (payment?.amount || 0) / (1 + serviceFee)

  const maximumRefundableAmount = Math.min(
    (isInvoiceRefund
      ? refundOriginalPayment.refundableAmount
      : isDepositRefund
        ? selectedEstimatePayment?.amount
        : 0) ?? Infinity,
    invoiceAmount,
  )

  const validateRefundValue = ({
    state: { refundingValue },
  }: CustomFieldValidatorState) =>
    isInvoiceRefund || isDepositRefund
      ? refundingValue <= (Utils.round(maximumRefundableAmount, 2) || 0)
      : isPayout
        ? refundingValue <= (payment?.refundableAmount || 0) &&
          refundingValue <= (payment?.unappliedAmount || 0)
        : isOtherPaymentType
          ? refundingValue <= (payment?.amount || 0)
          : (Utils.round(refundingValue * (1 + serviceFee), 2) || 0) <=
            (payment?.amount || 0)

  const {
    fields: { refundingValue, reverseNotes },
    validate: validateRefund,
  } = useFields(
    [
      {
        name: 'refundingValue',
        initialValue:
          isInvoiceRefund || isDepositRefund
            ? maximumRefundableAmount
            : depositExceedsInvoiceAmountRefund
              ? payment?.unappliedAmount
              : initialRefundValue,
        validators: [
          'greaterThanZero',
          { validator: validateRefundValue, validatorName: 'incorrectRefund' },
        ],
        messages: {
          incorrectRefund: isPayout
            ? t('Validations:PAYOUT_VALUE')
            : t('Validations:INCORRECT_REFUND'),
        },
      },
      {
        name: 'reverseNotes',
        validators: isReverse ? ['required'] : [],
        initialValue: '',
      },
    ],
    false,
  )

  // For refund invoices with multiple payments, refunding value is equal
  // to the invoice amount on initial render of refund amount input
  // component. After selecting one of the payment methods, we need to
  // re-initialize the refunding value, which will trigger if the refundable
  // amount or invoice amount is updated, in order for it to start off as
  // as the maximum refundable amount.
  useEffect(() => {
    if (isInvoiceRefund || isDepositRefund) {
      refundingValue.setValue(maximumRefundableAmount)
    }
  }, [maximumRefundableAmount])

  useEffect(() => {
    if (payment && isPayout) {
      dispatch(findOnAccountOriginPayment(payment.id))
    }
  }, [payment, isPayout])

  const {
    fields: { voidNotes },
    validate: validateVoid,
  } = useFields(
    [
      {
        name: 'voidNotes',
        validators: isIpoM0VoidAndPaymentReversalFTEnabled
          ? ['required']
          : undefined,
        initialValue: '',
      },
    ],
    false,
  )

  const {
    fields: { invoiceRefundMethod },
  } = useFields(
    [
      {
        name: 'invoiceRefundMethod',
      },
    ],
    false,
  )

  const {
    fields: { amount, notes },
    validate,
  } = useFields(
    [
      {
        name: 'amount',
        initialValue: paymentAmount || invoiceAmount || '',
        validators: ['greaterThanZero'],
      },
      {
        name: 'notes',
        initialValue:
          (isDepositRefund
            ? selectedEstimatePayment?.notes
            : isInvoiceRefund
              ? refundOriginalPayment?.notes
              : payment?.notes) ?? '',
      },
    ],
    false,
  )

  const invoiceRefundPosMethodItems = [
    {
      name: t('Common:PAYMENTS.REFUND_ON_ACCOUNT'),
      value: RefundPaymentMethods.ON_ACCOUNT,
    },
  ]

  const invoiceRefundMethodItems = [
    {
      name: t('Common:PAYMENTS.CASH'),
      value: RefundPaymentMethods.CASH,
    },
    {
      name: t('Common:PAYMENTS.CHECK'),
      value: RefundPaymentMethods.CHECK,
    },
  ]

  if (is3pClinic) {
    invoiceRefundMethodItems.push({
      name: t('Common:PAYMENTS.REFUND_ON_ACCOUNT'),
      value: RefundPaymentMethods.ON_ACCOUNT,
    })
  }

  const serviceFeeAmount = serviceFee
    ? Utils.round(amount.value * serviceFee, 2)
    : 0
  const refundFeeAmount =
    serviceFee && !isOtherPaymentType && !isInvoiceRefund && !isPayout
      ? Utils.round(refundingValue.value * serviceFee, 2) || 0
      : 0
  const showBalance =
    !isRefund &&
    !isReverse &&
    !isVoid &&
    !isInvoiceRefund &&
    !isPayout &&
    !isDepositRefund

  const refundingFixedValue = Utils.round(refundingValue.value, 2) || 0

  const handleClose = () => {
    onOk()
    dispatch(clearTransactionInfo())
  }

  const submitPosPayment = () => {
    if (validate()) {
      const thisPayment = {
        paymentTypeId: Utils.findConstantIdByName(
          paymentTypeName,
          PaymentTypeConstant,
        ),
        amount: Number(paymentAmount),
      } as ExtendPayment

      if (assignedInvoiceId) {
        thisPayment.invoiceId = assignedInvoiceId
      }

      if (invoiceIds) {
        thisPayment.invoiceIds = invoiceIds
      }

      dispatch(createPaymentTransaction(clientId ?? '', thisPayment))
    }
  }

  const recordManually = () => {
    dispatch(clearTransactionInfo())
    setTransaction({})
    onContinueToPaymentDetails()
  }

  const onCancelRequested = () => {
    setTransaction({})
    if (!R.isEmpty(payment)) {
      handleClose()
    } else {
      recordManually()
    }
  }

  const getPaymentInfo = (thisPayment: ExtendPayment | Nil) => {
    const { amount: thisAmount, businessId: paymentBusinessId } =
      thisPayment || {}

    return {
      amount: thisAmount,
      businessId: paymentBusinessId || businessId,
      personId: clientId,
      userId,
      goTxId,
      ...(goStripeTxId ? { goStripeTxId } : {}),
    }
  }

  const refundOnAccount = () => {
    if (refundOriginalPayment) {
      dispatch(
        createRefundOnAccount({
          refundOnAccount: {
            amount: refundingFixedValue + refundFeeAmount,
            originPaymentId: refundOriginalPayment.id,
            refundInvoiceId: refundInvoice.id,
          },
        }),
      )
    }
  }

  const submitRefundOrReverse = () => {
    if (!validateRefund()) {
      return
    }

    const originPosTxId = isInvoiceRefund
      ? refundOriginalPayment.posTxId
      : isDepositRefund
        ? selectedEstimatePayment.posTxId
        : isPayout
          ? onAccountOriginPayment?.posTxId
          : payment?.posTxId

    const isPOS = Boolean(originPosTxId)

    if (invoiceRefundMethod.value === RefundPaymentMethods.ON_ACCOUNT) {
      setCloseAfterCreationOn()
      refundOnAccount()
      return
    }

    const refundedPayment = isReversePayment
      ? {
          // regular reverse payment object
          paymentTypeId: Utils.findConstantIdByName(
            PaymentType.REVERSE_CHARGE,
            PaymentTypeConstant,
          ),
          reversed: true,
          notes: reverseNotes.value,
          originPaymentId: isPayout && isPOS ? undefined : payment?.id,
          onAccountPaymentId: isPayout ? payment?.id : undefined,
        }
      : {
          paymentTypeId: Utils.findConstantIdByName(
            PaymentType.REVERSE_CHARGE,
            PaymentTypeConstant,
          ),
          amount: refundingFixedValue + refundFeeAmount,
          notes: isReverse
            ? reverseNotes.value
            : depositExceedsInvoiceAmountRefund
              ? notes.value
              : payment?.notes,
          originPaymentId:
            isPayout && isPOS
              ? undefined
              : isInvoiceRefund
                ? refundOriginalPayment.id
                : isDepositRefund
                  ? selectedEstimatePayment.id
                  : payment?.id,
          onAccountPaymentId: isPayout ? payment?.id : undefined,
          originRhapsodyTxId: isPayout ? undefined : originPosTxId,
          refundInvoiceId: isInvoiceRefund ? refundInvoice?.id : undefined,
          paymentMethodId: invoiceRefundMethod?.value
            ? refundPaymentMethodsToIdsMap[
                invoiceRefundMethod.value as RefundPaymentMethods
              ]
            : undefined,
          reversed: isReverse,
          paidByPersonId: isInvoiceRefund
            ? refundOriginalPayment.paidByPersonId
            : isDepositRefund
              ? selectedEstimatePayment.paidByPersonId
              : isPayout
                ? onAccountOriginPayment?.paidByPersonId
                : payment?.paidByPersonId,
        }

    const submitClientId = clientId || payment?.clientId || ''
    if (!isPOS || isReversePayment) {
      setCloseAfterCreationOn()
      dispatch(
        createPayment(
          submitClientId,
          refundedPayment as unknown as UnsavedExtendPayment,
        ),
      ) // objects dont map correctly and we dont want to break anything
    } else {
      dispatch(
        createPaymentTransaction(
          submitClientId,
          refundedPayment as unknown as ExtendPayment,
        ),
      ) // ditto
    }
  }

  const submitRhapsodyGoRefundOrReverseInner = () => {
    const refundedPayment = getPaymentInfo(
      isInvoiceRefund
        ? refundOriginalPayment
        : isDepositRefund
          ? selectedEstimatePayment
          : isPayout
            ? onAccountOriginPayment
            : payment,
    )
    const paymentMethod = isInvoiceRefund
      ? refundOriginalPaymentMethodName
      : isDepositRefund
        ? selectedEstimatePayment.paymentMethod
        : isPayout
          ? onAccountOriginPaymentMethodName
          : payment?.paymentMethod
    const transactionTypeForRefund =
      paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD
        ? PaymentType.REFUND
        : PaymentType.ACH_REFUND

    const notesValue = isReverse ? reverseNotes.value : notes.value
    if (transactionTypeForRefund === PaymentType.ACH_REFUND) {
      updatePaymentAmount(refundingFixedValue + refundFeeAmount)
      onContinueToBillingAddresses('AchRefund')
    } else if (goTxId) {
      dispatch(
        refundRhapsodyGoTransaction(
          {
            ...refundedPayment,
            amount: refundingFixedValue + refundFeeAmount,
            notes: notesValue,
            refundInvoiceId: isInvoiceRefund ? refundInvoice.id : undefined,
            reversed: isReverse, // reversed does not exist on type ExtendPayment. Not trying to break this.
            onAccountPaymentId: isPayout ? payment?.id : undefined,
          } as unknown as ExtendPayment, // Casting because ^^^
          transactionTypeForRefund,
        ),
      )
    } else if (goStripeTxId) {
      dispatch(
        refundRhapsodyGoStripeTransaction(
          {
            ...refundedPayment,
            amount: refundingFixedValue + refundFeeAmount,
            notes: notesValue,
            refundInvoiceId: isInvoiceRefund ? refundInvoice.id : undefined,
            reversed: isReverse, // ditto
            onAccountPaymentId: isPayout ? payment?.id : undefined,
          } as unknown as ExtendPayment, // ditto
          transactionTypeForRefund,
        ),
      )
    }
  }

  const submitRhapsodyGoRefund = () => {
    if (!validateRefund()) {
      return
    }

    if (invoiceRefundMethod.value === RefundPaymentMethods.ON_ACCOUNT) {
      setCloseAfterCreationOn()
      refundOnAccount()
      return
    }

    if (isInvoiceRefund && !isPayout) {
      openConfirmAlert({
        applyCustomMessage: true,
        message: t('Invoices:PAYMENTS.REFUND_INVOICE.CONFIRM_DIALOG_MESSAGE', {
          amount: refundingFixedValue + refundFeeAmount,
        }),
        content: t('Invoices:PAYMENTS.REFUND_INVOICE.CONFIRM_DIALOG_CONTENT'),
        onConfirm: (proceed) =>
          proceed && submitRhapsodyGoRefundOrReverseInner(),
        okButtonText: refundOriginalPayment.posTxId
          ? t('Common:YES_SEND_TO_TERMINAL')
          : t('Common:YES'),
        cancelButtonText: t('Common:NO_I_DO_NOT_DONE'),
      })
    } else {
      submitRhapsodyGoRefundOrReverseInner()
    }
  }

  const resendTransaction = () => {
    if (!R.isEmpty(payment)) {
      submitRefundOrReverse()
    } else {
      submitPosPayment()
    }
    setTransaction({})
  }

  const onVoid = () => {
    const voidedPayment = {
      ...getPaymentInfo(payment),
      ...(isIpoM0VoidAndPaymentReversalFTEnabled
        ? { notes: voidNotes.value }
        : {}),
    } as ExtendPayment
    const transactionType =
      payment?.paymentMethod === RhapsodyGoPaymentMethod.CREDIT_CARD
        ? PaymentType.VOID
        : PaymentType.ACH_VOID

    if (goStripeTxId) {
      dispatch(voidRhapsodyGoStripeTransaction(voidedPayment, transactionType))
    } else {
      dispatch(voidRhapsodyGoTransaction(voidedPayment, transactionType))
    }
  }

  const submitVoid = () => {
    if (!isIpoM0VoidAndPaymentReversalFTEnabled) {
      onVoid()
    }

    if (!validateVoid()) {
      return
    }

    const paymentInvoices = getPaymentInvoiceIds(payment)

    openAlert({
      okButtonText: t('Common:YES'),
      cancelButtonText: t('Common:NO_GO_BACK'),
      onOk: () => {
        onVoid()
        closeAlert()
      },
      onCancel: () => {
        closeAlert()
      },
      message:
        paymentInvoices.length > 0
          ? t('Common:PAYMENTS.VOID_CONFIRM_WITH_INVOICE')
          : t('Common:PAYMENTS.VOID_CONFIRM'),
    })
  }

  const recordAdjustment = () => {
    if (validate()) {
      const adjustmentToRecord = {
        amount: adjustment.type === 'Credit' ? -paymentAmount : paymentAmount,
        notes: adjustment.notes,
        paidByPersonId: clientId,
        paymentMethodId: adjustmentPaymentMethod,
        paymentTypeId: adjustmentPaymentType,
      } as UnsavedExtendPayment
      setCloseAfterCreationOn()
      dispatch(createPayment(clientId, adjustmentToRecord))
    }
  }

  const onPaymentTypeSelected = (value = paymentType) => {
    if (!validate()) {
      return null
    }

    switch (value) {
      case PaymentTypes.CREDIT_CARD:
        return onContinueToBillingAddresses()
      case PaymentTypes.PRE_AUTHORIZATION:
        return onContinueToBillingAddresses('Auth')
      case PaymentTypes.OTHER:
        return recordManually()
      default:
        return null
    }
  }

  const onRefundPaymentMethodSelected = (value: any) => {
    invoiceRefundMethod.setValue(value)
    if (is3pClinic) {
      const dropDownElement = invoiceRefundMethodItems.find(
        (item) => item.value === value,
      )
      if (dropDownElement && dropDownElement.name) {
        setRefundOptionDropDownName(dropDownElement.name)
      }
    }
  }

  const onProceed = () => {
    onPaymentTypeSelected()
  }

  const onRefund =
    goTxId || goStripeTxId ? submitRhapsodyGoRefund : submitRefundOrReverse

  const onReverse = () => {
    if (!validateRefund()) {
      return
    }

    const paymentInvoices = getPaymentInvoiceIds(payment)

    if (isReverseGo || (isReversePayment && paymentInvoices.length > 0)) {
      openAlert({
        okButtonText: payment?.posTxId
          ? t('Common:YES_SEND_TO_TERMINAL')
          : t('Common:YES'),
        cancelButtonText: t('Common:NO_GO_BACK'),
        onOk: () => {
          onRefund()
          closeAlert()
        },
        onCancel: () => {
          closeAlert()
        },
        message:
          paymentInvoices.length > 0
            ? t('Common:PAYMENTS.REVERSE_CONFIRM_WITH_INVOICE')
            : t('Common:PAYMENTS.REVERSE_CONFIRM'),
      })
    } else {
      onRefund()
    }
  }

  const onPayout = () => {
    onRefund()
  }

  useEffect(() => {
    if (amount.value && posPayEnabled && serviceFeeAmount && !isAdjustment) {
      updatePaymentAmount(Utils.round(amount.value + serviceFeeAmount, 2) || 0)
    } else if (amount.value) {
      updatePaymentAmount(amount.value)
    }
  }, [amount.value, serviceFeeAmount])

  useEffect(() => {
    if (rhapsodyGoCompletedPayment) {
      if (rhapsodyGoCompletedPayment.state === VOIDED) {
        openAlert({
          okButtonText: t('Common:OK'),
          onOk: () => closeAlert(),
          message: t(
            'Invoices:PAYMENTS.PAYMENT_INITIALIZATION.TRANSACTION_HAS_BEEN_VOIDED',
          ),
        })
      } else {
        const paymentId = (rhapsodyGoCompletedPayment as any).payment // Type Payment.payment doesnt exist. Most likely an issue with return types never being strict.
        const thisPayment = paymentId ? undefined : rhapsodyGoCompletedPayment
        openPaymentDialog({
          clientId,
          payment: thisPayment,
          paymentId,
        })
      }
      handleClose()
    }
  }, [rhapsodyGoCompletedPayment])

  useEffect(() => {
    if (businessId) {
      dispatch(fetchRhapsodyPayConfig(businessId))
    }
  }, [businessId])

  return (
    <Grid container item direction="column">
      <Grid container item>
        <Grid container item direction="row" xs={7}>
          {refundingValue.value > refundOriginalPayment?.amount && (
            <Grid container item mt={1} pl={1} pr={1}>
              <AlertLabel
                message={t('Errors:REFUND_INVOICE_PAYMENT_EXCEEDED')}
              />
            </Grid>
          )}
          <Grid
            container
            item
            className={
              isAdjustment ? classes.adjustPaymentInfo : classes.paymentInfo
            }
          >
            {isAdjustment && (
              <FormControl className={classes.formControl} component="fieldset">
                <FormLabel
                  className={classes.formLabel}
                  component="legend"
                  focused={false}
                >
                  {t('Common:TYPE_ONE')}
                </FormLabel>
                <RadioGroup
                  aria-label="adjustmentTypes"
                  name="adjustments"
                  value={adjustment.type}
                  onChange={(_, value) =>
                    updateAdjustment({ ...adjustment, type: value })
                  }
                >
                  {ADJUSTMENT_TYPES.map((thisAdjustment) => (
                    <FormControlLabel
                      classes={{
                        root: classes.labelRoot,
                        label: classes.radioLabel,
                      }}
                      control={<Radio className={classes.radio} />}
                      key={thisAdjustment.paymentType}
                      label={thisAdjustment.label}
                      value={thisAdjustment.paymentType}
                    />
                  ))}
                </RadioGroup>
              </FormControl>
            )}
            {!isRefund &&
              !isReverse &&
              !isVoid &&
              !isInvoiceRefund &&
              !isDepositRefund &&
              !isPayout && (
                <>
                  {!isAdjustment && posPayEnabled && (
                    <FormControl
                      className={classes.formControl}
                      component="fieldset"
                    >
                      <FormLabel
                        className={classes.formLabel}
                        component="legend"
                        focused={false}
                      >
                        {t('Common:TYPE_ONE')}
                      </FormLabel>
                      <RadioGroup
                        aria-label="paymentTypes"
                        name="payments"
                        value={paymentType}
                        onChange={(_, value) =>
                          setPaymentType(value as PaymentTypes)
                        }
                      >
                        {MAIN_PAYMENT_TYPES.map((thisAdjustment) => (
                          <FormControlLabel
                            classes={{
                              root: classes.labelRoot,
                              label: classes.radioLabel,
                            }}
                            control={<Radio className={classes.radio} />}
                            key={thisAdjustment.paymentType}
                            label={thisAdjustment.label}
                            value={thisAdjustment.paymentType}
                          />
                        ))}
                      </RadioGroup>
                    </FormControl>
                  )}
                  <Grid container item>
                    <Grid container item xs={10}>
                      <Grid
                        container
                        item
                        alignItems="center"
                        justifyContent={
                          isAdjustment ? 'flex-start' : 'space-between'
                        }
                      >
                        <Grid
                          item
                          className={classes.chargeLabel}
                          xs={isAdjustment ? 4 : 3}
                        >
                          <Text
                            strong={isAdjustment || isOtherPaymentType}
                            variant="body"
                          >
                            {isAdjustment || isOtherPaymentType
                              ? t('Common:AMOUNT')
                              : t('Common:CHARGE_ACTION')}
                            :
                          </Text>
                        </Grid>
                        <Grid item>
                          <CurrencyTextField
                            className={classes.currencyFieldWrapper}
                            disabled={isLoading}
                            field={{
                              ...amount,
                              set: handleNumberInput(
                                (value: string) => {
                                  const formattedValue = parseFloat(value) || 0
                                  amount.setValue(formattedValue)
                                },
                                7,
                                2,
                                true,
                              ),
                            }}
                            inputProps={{
                              className: classNames(classes.currencyTextField, {
                                [classes.currencyTextFieldBold]:
                                  isAdjustment || isOtherPaymentType,
                              }),
                            }}
                          />
                        </Grid>
                      </Grid>
                      {posPayEnabled &&
                        !isAdjustment &&
                        !isOtherPaymentType && (
                          <Grid container item direction="column" pt={1.25}>
                            <Grid
                              container
                              item
                              alignItems="baseline"
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Text variant="body">
                                  {t(
                                    'Invoices:PAYMENTS.PAYMENT_INITIALIZATION.SERVICE_FEE',
                                  )}
                                  :
                                </Text>
                              </Grid>
                              <Grid item>
                                <Text variant="body">
                                  {NumberUtils.formatMoney(serviceFeeAmount)}
                                </Text>
                              </Grid>
                            </Grid>
                            <Grid
                              container
                              item
                              alignItems="baseline"
                              className={classes.totalChargeInput}
                              justifyContent="space-between"
                            >
                              <Grid item>
                                <Text strong variant="body">
                                  {t('Common:TOTAL_CHARGE')}:
                                </Text>
                              </Grid>
                              <Grid item>
                                <Text strong variant="body">
                                  {NumberUtils.formatMoney(paymentAmount)}
                                </Text>
                              </Grid>
                            </Grid>
                          </Grid>
                        )}
                    </Grid>
                    {posPayEnabled && !isAdjustment && !isOtherPaymentType && (
                      <Grid item xs className={classes.tooltipContainer}>
                        <TextWithTooltip
                          classes={{
                            infoIcon: classes.infoIcon,
                          }}
                          tooltipText={PAYMENT_AMOUNT_HINT}
                        />
                      </Grid>
                    )}
                  </Grid>
                </>
              )}
            <Grid container direction="column">
              {isAdjustment && (
                <Grid item className={classes.adjustmentNotesContainer}>
                  <PuiTextArea
                    InputProps={{
                      inputProps: { maxLength: paymentNotesMaxLength },
                    }}
                    placeholder={t('Common:NOTES')}
                    value={adjustment.notes}
                    onChange={Utils.handleFormTextInput((value) => {
                      updateAdjustment({ ...adjustment, notes: value })
                    })}
                  />
                  {!R.isEmpty(currentUser) && (
                    <Text variant="body">
                      {`${t(
                        'Common:PAYMENTS.RECORDED_BY',
                      )}: ${Utils.getPersonString(currentUser)}`}
                    </Text>
                  )}
                </Grid>
              )}
              {(isRefund || isReverse) &&
                !isInvoiceRefund &&
                !isDepositRefund && (
                  <Grid container>
                    <Grid
                      container
                      item
                      alignItems="baseline"
                      justifyContent="space-between"
                    >
                      {isReverse ? (
                        <TextWithTooltip
                          strong
                          tooltipText={t(
                            'Common:PAYMENTS.REVERSE_PAYMENT_TOOLTIP',
                          )}
                          variant="body"
                        >
                          {t('Common:PAYMENTS.REVERSE_PAYMENT')}
                          {': '}
                          {NumberUtils.formatMoney(
                            isReversePayment
                              ? payment?.amount
                              : refundingValue.value,
                          )}
                        </TextWithTooltip>
                      ) : (
                        <>
                          {' '}
                          <Text variant="body">
                            {t('Common:PAYMENTS.REFUND_AMOUNT')}:
                          </Text>
                          <CurrencyTextField
                            className={classes.currencyFieldRefund}
                            disabled={isLoading}
                            field={{
                              ...refundingValue,
                              set: handleNumberInput(
                                (value: string) => {
                                  const formattedValue = parseFloat(value) || 0
                                  refundingValue.setValue(formattedValue)
                                },
                                7,
                                2,
                                true,
                              ),
                            }}
                            inputProps={{
                              className: classNames(
                                classes.currencyTextField,
                                classes.currencyTextFieldBold,
                              ),
                            }}
                          />
                        </>
                      )}
                    </Grid>
                    {refundFeeAmount > 0 &&
                      !isOtherPaymentType &&
                      !isReversePayment && (
                        <Grid container item direction="column" pt={1.25}>
                          <Grid
                            container
                            item
                            alignItems="baseline"
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Text variant="body">
                                {t('Common:PAYMENTS.SERVICE_FEE_REFUND')}:
                              </Text>
                            </Grid>
                            <Grid item>
                              <Text variant="body">
                                {NumberUtils.formatMoney(refundFeeAmount)}
                              </Text>
                            </Grid>
                          </Grid>
                          <Grid
                            container
                            item
                            alignItems="baseline"
                            className={classes.totalChargeInput}
                            justifyContent="space-between"
                          >
                            <Grid item>
                              <Text strong variant="body">
                                {t('Common:PAYMENTS.TOTAL_REFUND')}:
                              </Text>
                            </Grid>
                            <Grid item>
                              <Text strong variant="body">
                                {NumberUtils.formatMoney(
                                  refundingValue.value + refundFeeAmount,
                                )}
                              </Text>
                            </Grid>
                          </Grid>
                        </Grid>
                      )}
                    {isReverse && (
                      <Grid pt={1.5}>
                        <PuiTextArea
                          InputProps={{
                            inputProps: { maxLength: paymentNotesMaxLength },
                          }}
                          field={reverseNotes}
                          label={t('Common:PAYMENTS.REVERSE_NOTES')}
                          margin="none"
                          placeholder={t(
                            'Common:PAYMENTS.REVERSE_NOTES_PLACEHOLDER',
                          )}
                        />
                      </Grid>
                    )}
                  </Grid>
                )}
              {isPayout && (
                <Grid
                  container
                  item
                  alignItems="baseline"
                  justifyContent="space-between"
                >
                  <>
                    {' '}
                    <Text variant="body">
                      {t('Common:PAYMENTS.PAYOUT_AMOUNT')}:
                    </Text>
                    <CurrencyTextField
                      className={classes.currencyFieldRefund}
                      disabled={isLoading}
                      field={{
                        ...refundingValue,
                        set: handleNumberInput(
                          (value: string) => {
                            const formattedValue = parseFloat(value) || 0
                            refundingValue.setValue(formattedValue)
                          },
                          7,
                          2,
                          true,
                        ),
                      }}
                      inputProps={{
                        className: classNames(
                          classes.currencyTextField,
                          classes.currencyTextFieldBold,
                        ),
                      }}
                    />
                  </>
                </Grid>
              )}
              {isInvoiceRefund && (
                <PaymentInvoiceRefundPaymentSelection
                  assignedInvoiceId={assignedInvoiceId || ''}
                  fields={{ invoiceRefundMethod, refundingValue, notes }}
                  invoiceAmount={invoiceAmount}
                  isLoading={isLoading}
                  refundPaymentId={refundOriginalPaymentId}
                  onSetRefundPaymentId={(id) => {
                    setRefundOriginalPaymentId(id)
                  }}
                />
              )}
              {isDepositRefund && (
                <PaymentDepositRefundPaymentSelection
                  assignedInvoiceId={estimateId}
                  fields={{ invoiceRefundMethod, refundingValue, notes }}
                  invoiceAmount={invoiceAmount}
                  isLoading={isLoading}
                  refundPaymentId={estimatePaymentId}
                  onSetRefundPaymentId={(id) => setEstimatePaymentId(id)}
                />
              )}
              {isVoid && (
                <>
                  <TextWithTooltip
                    strong
                    tooltipText={t('Common:PAYMENTS.VOID_TOOLTIP')}
                    variant="body"
                  >{`${t('Common:VOID')}: ${NumberUtils.formatMoney(
                    payment?.amount,
                  )}`}</TextWithTooltip>
                  {isIpoM0VoidAndPaymentReversalFTEnabled && (
                    <Grid pt={1.5}>
                      <PuiTextArea
                        InputProps={{
                          inputProps: { maxLength: paymentNotesMaxLength },
                        }}
                        field={voidNotes}
                        label={t('Common:PAYMENTS.VOID_NOTES')}
                        margin="none"
                        placeholder={t(
                          'Common:PAYMENTS.VOID_NOTES_PLACEHOLDER',
                        )}
                      />
                    </Grid>
                  )}
                </>
              )}
              {payment?.notes && !depositExceedsInvoiceAmountRefund && (
                <Grid item>
                  <Grid
                    container
                    item
                    className={classes.paymentNotesContainer}
                    direction="column"
                  >
                    <Text
                      strong
                      className={classes.paymentNotesLabel}
                      variant="subheading3"
                    >
                      {t('Common:PAYMENTS.PAYMENT_NOTES')}:
                    </Text>
                    <Text variant="body2">{payment.notes}</Text>
                  </Grid>
                </Grid>
              )}
              {(depositExceedsInvoiceAmountRefund || isDepositRefund) && (
                <PuiTextArea
                  InputProps={{
                    inputProps: { maxLength: paymentNotesMaxLength },
                  }}
                  field={notes}
                  label={t('Common:PAYMENTS.REFUND_NOTES')}
                  margin="none"
                />
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={5}>
          <PaymentClientNotesSection
            clientId={clientId}
            depositExceedsInvoiceAmountRefund={
              depositExceedsInvoiceAmountRefund
            }
            invoiceRefundAmount={invoiceAmount}
            invoiceRefundOriginalPayments={eligibleRefundOriginalPayments}
            isDepositRefund={isDepositRefund}
            isInvoiceRefund={isInvoiceRefund}
            isPayout={isPayout}
            isRefund={isRefund}
            isReverse={isReverse}
            isVoid={isVoid}
            payment={
              isInvoiceRefund
                ? refundOriginalPayment
                : isDepositRefund
                  ? selectedEstimatePayment
                  : payment
            }
            showBalance={showBalance}
            onAccountOriginPayment={onAccountOriginPayment}
          />
        </Grid>
      </Grid>
      <Grid container item alignItems="center" className={classes.footer}>
        {onBack && (
          <Grid item mr={3}>
            <BackButton label={t('Common:BACK_ACTION')} onClick={onBack} />
          </Grid>
        )}
        {!isRefund &&
          !isReverse &&
          !isInvoiceRefund &&
          !isDepositRefund &&
          !isVoid &&
          !isAdjustment &&
          !isOtherPaymentType &&
          !isPayout &&
          !onContinue && (
            <>
              <SubmitPosPaymentOrRecordManuallyButton
                className={classes.button}
                isLoading={isLoading}
                paymentAmount={paymentAmount}
                recordManually={recordManually}
                submitPosPayment={submitPosPayment}
              />
              <MorePaymentOptionsMenu
                isGoAvailableForPractice={isGoAvailableForPractice}
                isLoading={isLoading}
                paymentAmount={paymentAmount}
                onPaymentTypeSelected={onPaymentTypeSelected}
              />
              <OtherPaymentMethodButton
                isGoAvailableForPractice={isGoAvailableForPractice}
                isLoading={isLoading}
                recordManually={recordManually}
              />
            </>
          )}
        {onContinue && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading || !invoiceIds.length}
            loading={isLoading}
            onClick={onContinue}
          >
            {t('Common:FINISH_ACTION')}
          </ButtonWithLoader>
        )}
        {isOtherPaymentType && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={onProceed}
          >
            {t('Common:NEXT')}
          </ButtonWithLoader>
        )}
        {isRefund && !isInvoiceRefund && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={onRefund}
          >
            {t('Common:REFUND_ACTION')}
          </ButtonWithLoader>
        )}
        {isReverse && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={onReverse}
          >
            {t('Common:PAYMENTS.REVERSE_PAYMENT_ACTION')}
          </ButtonWithLoader>
        )}
        {isPayout && !isInvoiceRefund && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={onPayout}
          >
            {payoutButtonName}
          </ButtonWithLoader>
        )}
        {isDepositRefund && (
          <>
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading || !estimatePaymentId}
              loading={isLoading}
              onClick={
                goTxId || goStripeTxId
                  ? submitRhapsodyGoRefund
                  : submitRefundOrReverse
              }
            >
              {invoiceRefundMethod.value === RefundPaymentMethods.ON_ACCOUNT
                ? t('Common:REFUND_ACTION')
                : refundOriginalPayment.posTxId
                  ? t(
                      'Invoices:PAYMENTS.PAYMENT_INITIALIZATION.SEND_TO_TERMINAL',
                    )
                  : t('Common:REFUND_ACTION')}
            </ButtonWithLoader>

            {refundOriginalPayment.posTxId && is3pClinic && (
              <MenuDropdown
                linkButton
                className={classes.paymentTypes}
                classes={{
                  menu: classes.paymentTypesMenu,
                  button: classes.dropdownButton,
                }}
                disabled={isLoading || !Number(invoiceAmount)}
                items={invoiceRefundPosMethodItems}
                title={refundOptionDropDownName}
                onSelected={onRefundPaymentMethodSelected}
              />
            )}

            {!refundOriginalPayment.posTxId && (
              <MenuDropdown
                linkButton
                className={classes.paymentTypes}
                classes={{
                  menu: classes.paymentTypesMenu,
                  button: classes.dropdownButton,
                }}
                disabled={isLoading || !Number(invoiceAmount)}
                items={invoiceRefundMethodItems}
                title={refundOptionDropDownName}
                onSelected={onRefundPaymentMethodSelected}
              />
            )}
          </>
        )}
        {isInvoiceRefund && (
          <>
            <ButtonWithLoader
              className={classes.button}
              disabled={isLoading || !refundOriginalPayment?.id}
              loading={isLoading}
              onClick={
                goTxId || goStripeTxId
                  ? submitRhapsodyGoRefund
                  : submitRefundOrReverse
              }
            >
              {invoiceRefundMethod.value === RefundPaymentMethods.ON_ACCOUNT
                ? t('Common:REFUND_ACTION')
                : refundOriginalPayment.posTxId
                  ? t(
                      'Invoices:PAYMENTS.PAYMENT_INITIALIZATION.SEND_TO_TERMINAL',
                    )
                  : t('Common:REFUND_ACTION')}
            </ButtonWithLoader>

            {refundOriginalPayment.posTxId && is3pClinic && (
              <MenuDropdown
                linkButton
                className={classes.paymentTypes}
                classes={{
                  menu: classes.paymentTypesMenu,
                  button: classes.dropdownButton,
                }}
                disabled={isLoading || !Number(invoiceAmount)}
                items={invoiceRefundPosMethodItems}
                title={refundOptionDropDownName}
                onSelected={onRefundPaymentMethodSelected}
              />
            )}

            {!refundOriginalPayment.posTxId && (
              <MenuDropdown
                linkButton
                className={classes.paymentTypes}
                classes={{
                  menu: classes.paymentTypesMenu,
                  button: classes.dropdownButton,
                }}
                disabled={isLoading || !Number(invoiceAmount)}
                items={invoiceRefundMethodItems}
                title={refundOptionDropDownName}
                onSelected={onRefundPaymentMethodSelected}
              />
            )}
          </>
        )}
        {depositExceedsInvoiceAmountRefund && (
          <ButtonWithLoader
            className={classes.largeButton}
            disabled={isLoading}
            loading={isLoading}
            onClick={handleClose}
          >
            {t('Common:PAYMENTS.REFUND_TO_CLIENT_BALANCE')}
          </ButtonWithLoader>
        )}
        {isVoid && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={submitVoid}
          >
            {t('Common:VOID_ACTION')}
          </ButtonWithLoader>
        )}
        {isAdjustment && (
          <ButtonWithLoader
            className={classes.button}
            disabled={isLoading}
            loading={isLoading}
            onClick={recordAdjustment}
          >
            {t('Common:RECORD_ACTION')}
          </ButtonWithLoader>
        )}
      </Grid>
      <POSPaymentFailureAlertDialog
        hasPayment={Boolean(payment)}
        open={transaction?.state === FAILED}
        setTransaction={setTransaction}
        transaction={transaction}
        onCancelRequested={onCancelRequested}
        onResendTransaction={resendTransaction}
      />
    </Grid>
  )
}

export default PaymentInitialization
