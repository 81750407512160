import { gql } from '@apollo/client'

const EVENT_FIELDS = gql`
  fragment EventFields on EventForEstimate {
    id
    name
    state {
      id
      name
      creationDate
      modificationDate
    }
    type {
      id
      name
      creationDate
      modificationDate
    }
    scheduledStartDatetime
    scheduledEndDatetime
  }
`

export const AMOUNT_FIELDS = gql`
  fragment AmountFields on BigDecimalValueRange {
    low
    high
  }
`

export const PATIENT_FOR_EVENT_FIELDS = gql`
  fragment PatientFieldsForEventFields on PatientView {
    id
    name
    dateOfBirth
    color
    species
    spayedNeutered
    gender
    weight {
      value
      date
    }
  }
`

export const ESTIMATE_FIELDS = gql`
  ${EVENT_FIELDS}
  ${AMOUNT_FIELDS}
  ${PATIENT_FOR_EVENT_FIELDS}
  fragment EstimateFields on Estimate {
    id
    businessId
    additionalDiscount
    clientId
    patientId
    eventId
    events {
      ...EventFields
    }
    assignedSoapDetails {
      eventId
      soapId
      itemsCopied
    }
    invoiceIds
    invoiceType
    invoiceNo
    name
    requiredDeposit
    requiredDepositAmountNoFee
    requiredDepositAmount
    serviceFee
    currencyId
    amount {
      ...AmountFields
    }
    amountNoFee {
      ...AmountFields
    }
    savedAmount {
      ...AmountFields
    }
    subtotal {
      ...AmountFields
    }
    totalTax {
      ...AmountFields
    }
    totalDiscount {
      ...AmountFields
    }
    serviceFeeAmount {
      ...AmountFields
    }
    paid
    paidAmount
    paidAmountNoFee
    patient {
      ...PatientFieldsForEventFields
    }
    dueToPay
    dueToPayNoFee
    state
    stateId
    personId
    invoiceDate
    creationDate
    modificationDate
    signer {
      id
      firstName
      lastName
      email
      mobilePhone
      photo
      photoThumbnail
    }
    posted
    postedById
    postedAt
    expirationDate
    internalNote
    innerModificationDate
    isClinicFromSpain
  }
`

export const INVOICE_LINE_ITEM_ARRANGEMENT_INPUT_FIELDS = gql`
  fragment InvoiceLineItemArrangementFields on InvoiceLineItemArrangement {
    invoiceLineItemId
    viewOrderNumber
  }
`

export const INVOICE_LINE_ITEM_FIELDS = gql`
  fragment InvoiceLineItemFields on InvoiceLineItem {
    id
    clientId
    creationDate
    discountAllowed
    declined
    eventId
    priceTaxable
    name
    producerId
    patientId
    extendedPrice
    prepaid
    prepaidRemaining
    priceUnits
    quantity
    groupSnapshotId
    usedQuantity
    subTotal
    orderNotes
    stateEntity: stateEntity {
      id
      name
    }
    soapId
    labTestVendorId
    labTestOrderId
    labTestType
    labTestTypeId
    logType
    logId
    updatedAt
    discount
    discountAmount
    discountPerc
    taxAmount
    taxed
    taxRate
    origin
    priceId
    priceItemId
    price
    procedureId
    procedureOrderId
    procedureCategoryId
    procedureModalityId
    procedureCode
    refundLineItems {
      id
      refundedInvoice {
        id
        creationDate
        state {
          id
          name
        }
      }
      refundQty
      restockQty
      price
      subTotal
    }
    inventoryControlled
    inventoryCategoryId
    modificationDate
    soapLogModificationDate
    totalRefundEligibleQty
    totalRestockEligibleQty
    wplanCharged
    wplanCoverId
    wplanLogId
  }
`

export const RETAIL_ORDER_LINE_ITEM_FIELDS = gql`
  fragment RetailOrderLineItemFields on RetailOrderLineItem {
    id
    business {
      name
      id
    }
    client {
      firstName
      lastName
      id
    }
    invoice {
      id
      type
    }
    appointment {
      id
    }
    soap {
      finalized
      updatedAt
      id
    }
    patient {
      id
    }
    name
    quantity
    origin
    prescriptionType
    price
    taxAmount
    shippingAmount
    discountAmount
    subTotal
    totalPrice
    declined
    sectionId
    notes
    autoshipFrequency
    autoshipUnit {
      id
      name
    }
    movedToCart
    viewOrderNumber
    viewSectionOrderNumber
    producerId
    createdById
    modifiedById
    creationDate
    originalPrice
    modificationDate
    partNumber
    logId
    logType
    logState {
      id
    }
    inventoryCategoryId
    prescription {
      id
      updatedAt
    }
  }
`

export const INVOICE_LINE_ITEM_TOOLTIP_FIELDS = gql`
  fragment InvoiceLineItemToolTipFields on InvoiceLineItem {
    id
    priceUnitsSize
    labTestVendorCode
    labTestVendorName
    labTestSpecimen
    labTestTestFor
    procedureDescription
    variationPackageTypeId
    variationStrength
    variationCustomForm
    variationFormId
    variationStrengthUnitsId
    variationPerPackageUnitsId
    variationDeliveryMethodId
    variationPerPackageAmount
    inventoryControlled
    unitId
    priceMaxTimeUnitSize
    priceMinTimeUnitSize
    timeUnitId
    timeUnitSize
    prescriptionType
  }
`

export const GROUPED_ITEM_FIELDS = gql`
  ${INVOICE_LINE_ITEM_FIELDS}
  ${INVOICE_LINE_ITEM_TOOLTIP_FIELDS}
  fragment GroupedItemFields on GroupedItem {
    id: groupId
    declined
    groupName
    subTotal
    groupSnapshotId
    groupId
    discount
    itemDiscount
    additionalDiscount
    taxAmount
    items {
      ...InvoiceLineItemFields
      ...InvoiceLineItemToolTipFields
    }
  }
`

export const BILLING_ACTIVITY_SECTION_PATIENT = gql`
  fragment BillingActivitySectionPatient on Patient {
    id
    name
  }
`

export const INVOICE_SECTION_EVENT = gql`
  fragment InvoiceSectionEvent on Appointment {
    id
    name
    type {
      id
      name
    }
    patient {
      id
    }
    assignedVetId
    resources {
      employee {
        id
        firstName
        lastName
      }
      responsibility {
        id
        name
      }
    }
  }
`
