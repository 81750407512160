import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { Grid, Hidden, IconButton, Theme, useMediaQuery } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AddButton,
  Nil,
  PermissionArea,
  Text,
  Utils,
} from '@pbt/pbt-ui-components'

import RequiredFieldsNotice from '~/components/common/inputs/RequiredFieldsNotice'
import Expander from '~/components/common/lists/Expander'
import DialogNames from '~/constants/DialogNames'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import {
  deleteInventory,
  editInventory,
  fetchInventory,
} from '~/store/actions/inventories'
import { deleteVariation as deleteVariationAction } from '~/store/actions/variations'
import { useIsDrug, useIsGlobalInventoryItem } from '~/store/hooks/orders'
import { getCRUDByArea } from '~/store/reducers/auth'
import {
  getInventory,
  getInventoryIsDeleting,
  getInventoryIsFetching,
  getInventoryIsSending,
} from '~/store/reducers/inventories'
import { EasterEggEvents, Variation } from '~/types'
import { createDeepEqualityComparator, getDeleteConfirmMessage } from '~/utils'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import Inventory, { InventoryHandle } from './Inventory'
import InventoryRestriction, {
  InventoryRestrictionHandle,
} from './InventoryRestriction'
import VariationsTable from './VariationsTable'

const equateToNull = (value: any) => value || null
const compareInventoryVariation = createDeepEqualityComparator({
  propertyPickers: {
    weightMinimum: equateToNull,
    pdmpReport: equateToNull,
    description: equateToNull,
    genderRestrictions: (value) => (value?.length ? value : null),
  },
})

const useStyles = makeStyles(
  (theme) => ({
    variationListItem: {
      borderBottom: theme.constants.tabBorder,
      height: 56,
    },
  }),
  { name: 'InventoryDetails' },
)

export interface InventoryDetailsProps {
  itemId?: string
  onClose: () => void
}

const InventoryDetails = ({
  itemId: inventoryId,
  onClose,
}: InventoryDetailsProps) => {
  const classes = useStyles()
  const { t } = useTranslation('Common')

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const inventory = useSelector(getInventory(inventoryId))
  const permissions = useSelector(getCRUDByArea(PermissionArea.INVENTORY))
  const isLoading = useSelector(getInventoryIsSending)
  const isDeleting = useSelector(getInventoryIsDeleting)
  const isFetching = useSelector(getInventoryIsFetching)

  const isMobile = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'))

  const setCloseOnDelete = useCloseAfterCreation(
    onClose,
    getInventoryIsDeleting,
  )

  const isGlobalItem = useIsGlobalInventoryItem(inventory)
  const isDrug = useIsDrug(inventory)

  const [isInventoryChanged, setIsInventoryChanged] = useState(false)

  const [openVariationDialog] = useDialog(DialogNames.VARIATION)
  const [openGlobalVariationSelectDialog] = useDialog(
    DialogNames.GLOBAL_VARIATION_SELECT,
  )
  const [openDeleteVariationAlert, closeDeleteVariationAlert] = useDialog(
    DialogNames.DISMISSIBLE_ALERT,
  )
  const [openInventoryDialog] = useDialog(DialogNames.INVENTORY)

  const onBackButtonClick = () => {
    navigate('/admin/catalog/inventories')
  }

  const handleOpenVariationDialog = (currentVariation?: Variation) => {
    if (isGlobalItem && !currentVariation) {
      openGlobalVariationSelectDialog({
        item: inventory,
      })
    } else {
      openVariationDialog({
        duplicate: !currentVariation?.id ? currentVariation : null,
        inventoryId,
        variationId: currentVariation?.id,
      })
    }
  }

  useEffect(() => {
    if (inventoryId) {
      dispatch(fetchInventory(inventoryId))
    }
  }, [inventoryId])

  const inventorySection = useRef<InventoryHandle>(null)
  const inventoryRestrictionSection = useRef<InventoryRestrictionHandle>(null)

  const getNewInventory = () => ({
    ...inventory,
    ...inventorySection.current?.get(),
    ...inventoryRestrictionSection.current?.getRestriction(),
  })

  const update = () => {
    if (inventorySection.current?.validate()) {
      const newInventory = getNewInventory()
      dispatch(editInventory(newInventory))
      dispatch(
        queueEasterEggEvent({
          actionType: EasterEggEvents.SAVE_INVENTORY,
        }),
      )
    }
  }

  const onDeleteRequested = () => {
    if (inventoryId) {
      setCloseOnDelete()
      dispatch(deleteInventory(inventoryId))
    }
  }

  const onCloneRequested = () => {
    openInventoryDialog({
      duplicateInventoryId: inventoryId,
    })
  }

  const editVariation = (variationId: string) => {
    const variation: Variation | Nil = Utils.findById(
      variationId,
      inventory?.variations || [],
    )
    if (variation) {
      handleOpenVariationDialog(variation)
    }
  }

  const duplicateVariation = (variationId: string) => {
    const variation: Variation | Nil = Utils.findById(
      variationId,
      inventory?.variations || [],
    )
    if (variation) {
      const clonedVariation = R.omit(
        ['id', 'businessId', 'inventoryItemId', 'priceId'],
        variation,
      ) as Variation
      handleOpenVariationDialog(clonedVariation)
    }
  }

  const deleteVariation = (variationId: string) => {
    if (!inventoryId) {
      return
    }

    const variation = Utils.findById(variationId, inventory?.variations || [])
    openDeleteVariationAlert({
      message: getDeleteConfirmMessage(variation?.name),
      cancelButtonText: t('Common:NO_KEEP'),
      okButtonText: t('Common:YES_DELETE'),
      onCancel: () => closeDeleteVariationAlert(),
      onOk: () => {
        dispatch(deleteVariationAction(inventoryId, variationId))
        closeDeleteVariationAlert()
      },
    })
  }

  const getUnsavedData = () => {
    const newInventory = getNewInventory()
    return (
      Boolean(inventory) &&
      !R.isEmpty(inventory) &&
      !isFetching &&
      !compareInventoryVariation(inventory, newInventory)
    )
  }

  if (!inventoryId) {
    return null
  }

  const onChange = () => {
    setIsInventoryChanged(getUnsavedData())
  }

  return (
    <Expander
      isConfirmToDelete
      expandedItemClass={t('Common:INVENTORY').toLowerCase()}
      getUnsavedData={getUnsavedData}
      hasUnsavedData={isInventoryChanged}
      isDeleting={isDeleting}
      isFetching={isFetching}
      isSaving={isLoading}
      onBack={onBackButtonClick}
      onCloneRequested={
        permissions.create && !isGlobalItem ? onCloneRequested : undefined
      }
      onDeleteRequested={permissions.delete ? onDeleteRequested : undefined}
      onSaveRequested={permissions.update ? update : undefined}
    >
      <Grid container spacing={3}>
        <Inventory
          inventory={inventory}
          ref={inventorySection}
          onChange={onChange}
        />
        <Grid item xs={12}>
          <RequiredFieldsNotice />
        </Grid>
        <InventoryRestriction
          inventory={inventory}
          ref={inventoryRestrictionSection}
          onChange={onChange}
        />
        <Grid item xs={12}>
          <Text strong mt={3} variant={isMobile ? 'body' : 'body2'}>
            {t('Common:VARIATIONS')}
          </Text>
        </Grid>
        <Grid item xs={12}>
          <Hidden mdDown>
            <VariationsTable
              createDisabled={!permissions.update}
              deleteDisabled={!permissions.update}
              editDisabled={!permissions.update}
              isDrug={isDrug}
              variations={inventory?.variations || []}
              onDelete={deleteVariation}
              onDuplicate={duplicateVariation}
              onEdit={editVariation}
            />
          </Hidden>
          <Hidden mdUp>
            {(inventory?.variations || []).map(({ id, name }) => (
              <Grid
                container
                alignItems="center"
                className={classes.variationListItem}
                justifyContent="space-between"
                key={id}
                onClick={() => editVariation(id)}
              >
                <Text pl={3} variant="body2">
                  {name}
                </Text>
                <IconButton
                  aria-label={t('Common:EDIT_VARIATION')}
                  size="large"
                >
                  <KeyboardArrowRight />
                </IconButton>
              </Grid>
            ))}
          </Hidden>
        </Grid>
        {permissions.update && (
          <Grid item xs={12}>
            <AddButton
              inline
              addText={t('Common:ADD_VARIATION')}
              onAdd={() => handleOpenVariationDialog()}
            />
            {inventory?.compoundable && (
              <AddButton
                inline
                addText={t('Common:ADD_COMPOUND_VARIATION')}
                onAdd={() => handleOpenVariationDialog({} as Variation)}
              />
            )}
          </Grid>
        )}
      </Grid>
    </Expander>
  )
}

export default InventoryDetails
