import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import InfoIcon from '@mui/icons-material/InfoOutlined'
import { Grid } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as R from 'ramda'
import {
  AlertIconType,
  ButtonWithLoader,
  NumberUtils,
  PuiTooltip,
  StateLabel,
  Text,
} from '@pbt/pbt-ui-components'
import { Warning as WarningIcon } from '@pbt/pbt-ui-components/src/icons'
import { findConstantIdByName } from '@pbt/pbt-ui-components/src/utils'

import { RefundInvoice } from '~/api/graphql/generated/types'
import useConfirmAlert from '~/components/common/dialog/useConfirmAlert'
import AlertLabel from '~/components/common/labels/AlertLabel'
import DialogNames, { ConfirmAlertType } from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import {
  FINANCE_TABLE_PADDING_X_SPACING_VALUE,
  REFUND_TABLE_MIN_WIDTH,
} from '~/constants/financeTable'
import { InvoiceViewStates } from '~/constants/refund'
import {
  getRefundCalculation,
  getRefundsError,
  getRefundsId,
  getRefundsLoading,
  resetRefundState,
} from '~/store/duck/refunds'
import {
  getFeatureToggle,
  getInvoiceStates,
  getRefundInvoiceState,
} from '~/store/reducers/constants'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'

import RefundInvoiceStatusLabel from '../../invoices/RefundInvoiceStatusLabel'
import InvoiceFinanceDropdownActions from './InvoiceFinanceDropdownActions'
import { InvoiceTotalsSectionForRefund } from './InvoiceTotalsSectionForRefund'
import { RefundContext } from './RefundContext'

const useStyles = makeStyles(
  (theme) => ({
    bottom: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
      backgroundColor: theme.colors.tableBackground,
    },
    button: {
      padding: theme.spacing(0, 2),
      margin: theme.spacing(0.5, 0),
    },
    buttonContainer: {
      '& button': {
        textTransform: 'none',
      },
    },
    total: {
      borderLeft: theme.constants.tableBorder,
      backgroundColor: theme.colors.tableLeftColumnBackground,
    },
    footNote: {
      backgroundColor: theme.colors.tableBackground,
    },
    warningIcon: {
      cursor: 'pointer',
      verticalAlign: 'middle',
      width: 18,
      marginRight: theme.spacing(1),
    },
    infoIcon: {
      cursor: 'pointer',
      verticalAlign: 'middle',
    },
    invoiceTotal: {
      borderTop: theme.constants.tableBorder,
      borderTopWidth: 1,
    },
    scrollInput: {
      overflowY: 'scroll',
    },
    tooltipLink: {
      display: 'contents',
    },
    refundLabel: {
      padding: theme.spacing(0.25, 1),
    },
    containerWithInfoPanel: {
      width: ({ isInfoPanelOpen }: { isInfoPanelOpen: boolean }) =>
        isInfoPanelOpen ? 'calc(100% - 400px)' : 'auto',
      minWidth: REFUND_TABLE_MIN_WIDTH,
    },
  }),
  { name: 'InvoiceFooterForRefunds' },
)

interface InvoiceFooterForRefundsProps {
  onSwitchInvoiceViewState: (viewState: InvoiceViewStates) => void
}

export const InvoiceFooterForRefunds = ({
  onSwitchInvoiceViewState,
}: InvoiceFooterForRefundsProps) => {
  const { t } = useTranslation(['Common', 'Invoices'])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const location = useLocation()

  const {
    invoice,
    isInfoPanelOpen,
    refundInvoice,
    viewState,
    tooltipInfo,
    validate,
    save,
    voidInvoice,
  } = useContext(RefundContext)
  const classes = useStyles({ isInfoPanelOpen })

  const refundInvoiceId = useSelector(getRefundsId)
  const refundInvoiceStates = useSelector(getRefundInvoiceState)
  const InvoiceStates = useSelector(getInvoiceStates)
  const refundInvoiceError = useSelector(getRefundsError)
  const refundCalculation = useSelector(getRefundCalculation(invoice?.id))
  const refundLoading = useSelector(getRefundsLoading)

  const isNoShowPenaltyRefundEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_REFUND),
  )

  const [openAddPaymentDialog] = useDialog(DialogNames.ADD_PAYMENT)
  const [openRefundCheckNumberDialog] = useDialog(
    DialogNames.REFUND_CHECK_NUMBER,
  )
  const [openPostInvoiceConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.POST_INVOICE,
  })
  const [openVoidInvoiceConfirmAlert] = useConfirmAlert({
    type: ConfirmAlertType.VOID_INVOICE,
  })
  const [openPostInvoiceFailureAlert] = useConfirmAlert({
    type: ConfirmAlertType.POST_INVOICE_FAILURE,
  })
  const [openAlert, closeAlert] = useDialog(DialogNames.DISMISSIBLE_ALERT)

  const summaryView = viewState === InvoiceViewStates.REFUND_SUMMARY
  const invoiceView = viewState === InvoiceViewStates.REFUND_INVOICE
  const landingView = viewState === InvoiceViewStates.REFUND_LANDING

  const pendingStateId = findConstantIdByName('Pending', refundInvoiceStates)
  const refundedStateId = findConstantIdByName('Refunded', refundInvoiceStates)
  const refundFailedStateId = findConstantIdByName(
    'Failed',
    refundInvoiceStates,
  )
  const partiallyPaidStateId = findConstantIdByName(
    'Partially Paid',
    InvoiceStates,
  )
  const voidedStateId = findConstantIdByName('Voided', refundInvoiceStates)
  const refundInvoiceStateId = refundInvoice?.state?.id

  const refundAmountValue = refundCalculation?.amount || refundInvoice?.amount
  const totalRefundPayments = R.reduce(
    (total, ri: RefundInvoice) =>
      total + R.sum(R.map((payment) => payment.amount, ri?.payments || [])),
    0,
    R.filter(
      (ri: RefundInvoice) => ri.state.id !== voidedStateId,
      invoice?.refunds || [],
    ) || [],
  )
  const maxRefundableAmount = invoice
    ? invoice.paidAmount - totalRefundPayments
    : 0

  const refundTooLarge = refundAmountValue > maxRefundableAmount
  const showMeatballMenu = !landingView && !summaryView
  const canVoid = refundInvoice?.state?.id === pendingStateId
  const showPartiallyPaidMsg =
    invoice?.stateId === partiallyPaidStateId && !invoiceView
  const showCancelButton =
    summaryView || viewState === InvoiceViewStates.REFUND_LANDING
  const showVoidButton = invoiceView && canVoid
  const showStatus = invoiceView && refundInvoiceStateId

  useEffect(() => {
    const hasPendingRefundInvoiceError = refundInvoiceError
      ?.toLowerCase()
      .includes('there is already a pending refund invoice')

    if (hasPendingRefundInvoiceError && refundInvoiceError) {
      const pendingRefundInvoiceId = refundInvoiceError.split(' ').pop()
      openPostInvoiceFailureAlert({
        applyCustomMessage: true,
        message: t('Invoices:PENDING_REFUND_INVOICE_EXISTS'),
        cancelButtonText: t('Common:RETURN_TO_INVOICE'),
        okButtonText: t('Common:GO_TO_REFUND_INVOICE'),
        onConfirm: (proceed: boolean) => {
          dispatch(resetRefundState())
          onSwitchInvoiceViewState(InvoiceViewStates.DEFAULT)
          if (proceed) {
            navigate(`/refund/${pendingRefundInvoiceId}`)
          } else if (invoice) {
            navigate(`/invoice/${invoice.id}`)
          } else {
            navigate(`/balance/${refundInvoice?.client?.id}`)
          }
        },
      })
    }
  }, [refundInvoiceError])

  const openRefundInvoiceAfterPost = useCloseAfterCreation(() => {
    if (refundInvoiceId) {
      navigate(`/refund/${refundInvoiceId}`)
    }
  }, getRefundsLoading)

  const navigateBackAfterVoid = useCloseAfterCreation(() => {
    if (refundInvoiceError) {
      openAlert({
        iconType: AlertIconType.WARN,
        message: refundInvoiceError,
        onOk: closeAlert,
      })

      return
    }

    const originalInvoiceId =
      refundInvoice?.originalInvoiceId ?? refundInvoice?.originalInvoice?.id
    if (location.key !== 'default') {
      navigate(-1)
    } else if (originalInvoiceId) {
      navigate(`/invoice/${originalInvoiceId}`)
    } else {
      navigate(
        `/balance/${
          invoiceView ? refundInvoice?.client.id : invoice?.clientId
        }`,
      )
    }
  }, getRefundsLoading)

  const postRefundInvoice = () => {
    openPostInvoiceConfirmAlert({
      applyCustomMessage: true,
      message: t('Invoices:POST_REFUND_MESSAGE'),
      okButtonText: t('Common:YES_POST_REFUND_INVOICE'),
      cancelButtonText: t(
        'Dialogs:CONFIRM_ALERT_DIALOG.POST_INVOICE.CANCEL_BUTTON',
      ),
      onConfirm: (proceed: boolean) => {
        if (proceed) {
          openRefundInvoiceAfterPost()
          save()
        }
      },
    })
  }

  const onVoidInvoice = () => {
    openVoidInvoiceConfirmAlert({
      applyCustomMessage: true,
      message: t('Invoices:VOID_REFUND_MESSAGE'),
      okButtonText: t('Common:YES_VOID_REFUND_INVOICE'),
      cancelButtonText: t('Common:NO_GO_BACK'),
      onConfirm: (proceed: boolean) => {
        if (proceed) {
          navigateBackAfterVoid()
          voidInvoice()
        }
      },
    })
  }

  const refundTotal = Number(refundInvoice?.amountNoFee)
  const refundPaid = Number(refundInvoice?.paidAmountNoFee)
  const leftToRefund = Math.max(0, refundTotal - refundPaid)

  const reopenRefundDialog = useCloseAfterCreation(() => {
    if (leftToRefund > 0) {
      // eslint-disable-next-line @typescript-eslint/no-use-before-define
      openRefundDialog()
    }
  }, getRefundsLoading)

  function openRefundDialog() {
    openAddPaymentDialog({
      clientId: invoice?.clientId ?? refundInvoice?.client.id,
      isInvoiceRefund: true,
      isRefund: true,
      onOk: () => {
        reopenRefundDialog()
      },
      ComponentProps: {
        invoiceAmount: leftToRefund,
        assignedInvoiceId: invoice?.id ?? refundInvoice?.id,
        invoiceIds: R.uniq([invoice?.id, refundInvoice?.id].filter(Boolean)),
      },
    })
  }

  const submitRefund = () => {
    openRefundDialog()
  }

  const submitEnterRefundCheckNumber = () => {
    openRefundCheckNumberDialog({
      clientId: invoice?.clientId ?? refundInvoice?.client.id,
    })
  }
  return (
    <Grid container className={classes.containerWithInfoPanel} mt="auto">
      <Grid container className={classes.bottom}>
        <Grid
          container
          item
          flexDirection="column"
          gap={1}
          justifyContent="flex-end"
          lg={9.15}
          md={6.5}
          px={FINANCE_TABLE_PADDING_X_SPACING_VALUE}
          py={2}
        >
          <Grid
            container
            item
            alignItems="flex-end"
            justifyContent="space-between"
          >
            {!invoiceView && (
              <Grid
                container
                item
                flexDirection="row"
                justifyContent="flex-start"
                mb={1}
              >
                {refundTooLarge && (
                  <Grid item>
                    <AlertLabel
                      message={
                        <>
                          <WarningIcon className={classes.warningIcon} />
                          {t('Invoices:REFUND_AMOUNT_GREATER_THAN_PAID')}
                        </>
                      }
                      ml={1}
                      variant="attention"
                    />
                  </Grid>
                )}
                {showPartiallyPaidMsg && (
                  <Grid item>
                    <StateLabel warning ml={1}>
                      {t('Invoices:MAX_REFUND_ALLOWED', {
                        amount: NumberUtils.formatMoney(maxRefundableAmount),
                      })}
                      <PuiTooltip
                        tooltipText={t(
                          'Invoices:ORIGINAL_INVOICE_PARTIALLY_PAID_TOOLTIP',
                        )}
                      >
                        <InfoIcon className={classes.infoIcon} />
                      </PuiTooltip>
                    </StateLabel>
                  </Grid>
                )}
              </Grid>
            )}
            {showStatus && (
              <Grid container item mb={1}>
                <Text strong variant="body2">
                  {t('Common:STATUS')}:
                </Text>
                <RefundInvoiceStatusLabel
                  className={classes.refundLabel}
                  ml={1}
                  stateId={refundInvoiceStateId}
                />
              </Grid>
            )}
            <Grid container item gap={2} width="auto">
              {invoiceView ? (
                isNoShowPenaltyRefundEnabled &&
                refundInvoiceStateId === refundFailedStateId ? (
                  <ButtonWithLoader
                    color="primary"
                    onClick={submitEnterRefundCheckNumber}
                  >
                    {t('Invoices:ENTER_REFUND_CHECK_NUMBER')}
                  </ButtonWithLoader>
                ) : (
                  <ButtonWithLoader
                    className={classes.button}
                    color="primary"
                    disabled={refundInvoiceStateId === refundedStateId}
                    loading={refundLoading}
                    onClick={submitRefund}
                  >
                    {t('Invoices:SUBMIT_REFUND_ACTION')}
                  </ButtonWithLoader>
                )
              ) : summaryView ? (
                <ButtonWithLoader
                  className={classes.button}
                  color="primary"
                  disabled={refundTooLarge}
                  loading={refundLoading}
                  onClick={() => {
                    if (validate()) {
                      postRefundInvoice()
                    }
                  }}
                >
                  {t('Invoices:POST_INVOICE_ACTION')}
                </ButtonWithLoader>
              ) : (
                <PuiTooltip
                  openOnClick
                  tooltipText={tooltipInfo.map((text, index) => (
                    <>
                      {/* eslint-disable-next-line react/no-array-index-key */}
                      <span key={`${text}_${index}`}>{text}</span>
                      {index === 0 ? <></> : <br />}
                    </>
                  ))}
                >
                  <span className={classes.buttonContainer}>
                    <ButtonWithLoader
                      className={classes.button}
                      color="primary"
                      disabled={refundTooLarge}
                      loading={refundLoading}
                      onClick={() => {
                        if (validate()) {
                          onSwitchInvoiceViewState(
                            InvoiceViewStates.REFUND_SUMMARY,
                          )
                        }
                      }}
                    >
                      {t('Common:NEXT_ACTION')}
                    </ButtonWithLoader>
                  </span>
                </PuiTooltip>
              )}
              {showVoidButton && (
                <ButtonWithLoader
                  className={classes.button}
                  color="primary"
                  loading={refundLoading}
                  onClick={onVoidInvoice}
                >
                  {t('Common:VOID_ACTION')}
                </ButtonWithLoader>
              )}
              {showCancelButton && (
                <ButtonWithLoader
                  className={classes.button}
                  color="secondary"
                  onClick={() =>
                    onSwitchInvoiceViewState(
                      summaryView
                        ? InvoiceViewStates.REFUND_LANDING
                        : InvoiceViewStates.DEFAULT,
                    )
                  }
                >
                  {t('Common:CANCEL_ACTION')}
                </ButtonWithLoader>
              )}
            </Grid>
            {showMeatballMenu && (
              <InvoiceFinanceDropdownActions
                actions={[]}
                invoice={invoiceView ? refundInvoice : invoice}
              />
            )}
          </Grid>
        </Grid>
        <Grid container item className={classes.total} lg={2.85} md={5.5}>
          <InvoiceTotalsSectionForRefund />
        </Grid>
      </Grid>
    </Grid>
  )
}
