/* eslint-disable max-lines */
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import classNames from 'classnames'
import * as R from 'ramda'
import {
  ButtonWithLoader,
  DateUtils,
  EventTypeName,
  Field,
  Nil,
  PermissionArea,
  StateLabel,
  Text,
  TextWithTooltip,
  useFields,
  Utils,
  ValidateHandle,
} from '@pbt/pbt-ui-components'

import {
  CardPaymentMethod,
  useChewyCardPaymentMethodsQuery,
} from '~/api/graphql/generated/types'
import { useGetIsNoShowConsentAppointmentType } from '~/components/common/appointments/useGetIsNoShowConsentAppointmentType'
import ActionsButton from '~/components/common/buttons/ActionsButton'
import DeleteButton from '~/components/common/buttons/DeleteButton'
import useConfirmAppointmentNoShowAlert from '~/components/common/dialog/useConfirmAppointmentNoShowAlert'
import AppointmentStatusSelect from '~/components/dashboard/soap/appointment/AppointmentStatusSelect'
import { GroupedOption } from '~/components/elements/OutlinedSelect/OutlinedSelect'
import { AppointmentState } from '~/constants/appointmentStates'
import {
  AppointmentTypeName,
  isHighValueAppointment,
} from '~/constants/appointmentTypes'
import { CancellationInfo } from '~/constants/cancellationReasonType'
import DialogNames from '~/constants/DialogNames'
import FeatureToggle from '~/constants/featureToggle'
import { createClient } from '~/store/actions/clients'
import { queueEasterEggEvent } from '~/store/actions/easterEgg'
import {
  clearAppointmentValidationError,
  createAppointment,
  createBusyTime,
  deleteBusyTime,
  editBusyTime,
  patchAppointment,
} from '~/store/actions/timetable'
import { getAppointmentType } from '~/store/reducers/appointmentTypes'
import {
  getCRUDByArea,
  getCurrentBusinessAppointmentCommunicationsConfiguration,
  getCurrentBusinessId,
  getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  getCurrentBusinessIsOmniChannel,
} from '~/store/reducers/auth'
import {
  getClientIsCreating,
  getClientsError,
  getClientsErrorStatus,
  getCurrentClientId,
} from '~/store/reducers/clients'
import {
  getAppointmentMissingPaymentMethodReasons,
  getEventType,
  getFeatureToggle,
} from '~/store/reducers/constants'
import { getCurrentPatientId } from '~/store/reducers/patients'
import {
  getTimetableError,
  getTimetableIsDeleting,
  getTimetableIsLoading,
  getTimetableIsSaving,
  getTimetableLastAppointmentId,
  getTimetableMultiValidationError,
  getTimetableValidationError,
} from '~/store/reducers/timetable'
import { getUser } from '~/store/reducers/users'
import { DataHandle, EasterEggEvents, TimetableEvent } from '~/types'
import { CardPaymentMethodOrMissingReason } from '~/types/entities/cardPaymentMethodOrMissingReason'
import {
  addOriginalBusinessId,
  isFieldValuesChanged,
  isNilOrEmpty,
} from '~/utils'
import { getIsOutsideCancellationWindow } from '~/utils/appointmentCancellationUtils'
import {
  getPaymentMethodById,
  getPrimaryCardPaymentMethod,
  sortCardPaymentMethodsByPrimary,
} from '~/utils/cvcClient'
import useCloseAfterCreation from '~/utils/useCloseAfterCreation'
import useDialog from '~/utils/useDialog'
import { useAppointmentStateId, useEventType } from '~/utils/useEventType'
import useFieldsChanged, { FieldCache } from '~/utils/useFieldsChanged'

import Appointment from './Appointment'
import AppointmentClientAndPatientDetails from './AppointmentClientAndPatientDetails'
import {
  getAppointmentHasFinalizedSoap,
  getAppointmentPatch,
  getIsRepeatedAppointment,
  getMissingPaymentMethodReason,
} from './appointmentUtils'
import Busy from './Busy'
import ClientCreate, { ClientHandle } from './ClientCreate'
import ClientSelector from './ClientSelector'
import { NoShowAlertText } from './NoShowAlertText'
import OpenSoapButton from './OpenSoapButton'

const useStyles = makeStyles(
  (theme) => ({
    root: {},
    actions: {
      zIndex: theme.utils.modifyZIndex(theme.zIndex.modal, 'above', 2),
    },
    content: {
      maxHeight: 380,
      overflowY: 'auto',
    },
    contentWithAddedFields: {
      maxHeight: 'max(380px, calc(100vh - 20rem))',
      overflowY: 'auto',
    },
    leftContainer: {
      backgroundColor: theme.colors.soapStatusBar,
    },
    leftContainerAppointmentMode: {
      backgroundColor: theme.colors.tableBackground,
    },
    radioLabel: {
      fontSize: '1.6rem',
    },
    iconButton: {
      width: 40,
      height: 40,
      border: theme.constants.hoverBorder,
    },
    footer: {
      boxShadow: '0 -1px 3px 0 rgba(0,0,0,0.1)',
    },
    icon: {
      fontSize: '2.2rem',
    },
    finalizedLabel: {
      paddingTop: 5,
      paddingBottom: 5,
      fontSize: '1.6rem',
    },
    card: {
      width: 150,
      height: 75,
    },
    reservedCard: {
      border: theme.constants.tableBorderSelected,
      borderStyle: 'dashed',
      borderWidth: 2,
      backgroundColor: theme.colors.contentBackground,
    },
    busyCard: {
      border: theme.constants.inactiveBorder,
      backgroundColor: theme.colors.listItemHover,
    },
    button: {
      minWidth: 160,
    },
  }),
  { name: 'AppointmentComponent' },
)

export interface AppointmentComponentHandle extends ValidateHandle {
  hasUnsavedChanges: () => boolean
  save: () => void
}

export interface AppointmentHandle extends DataHandle {
  getActions: any
}

export interface AppointmentComponentProps {
  appointment: TimetableEvent | Nil
  appointmentStateId?: string
  appointmentTypeId?: string
  clientId?: string | Nil
  onOk: () => void
  patientId?: string | Nil
  personId?: string
}

const AppointmentComponent = forwardRef<
  AppointmentComponentHandle,
  AppointmentComponentProps
>(function AppointmentComponent(
  {
    appointment,
    clientId: clientIdProp,
    personId: personIdProp,
    patientId: patientIdProp,
    appointmentTypeId,
    appointmentStateId,
    onOk,
  },
  ref,
) {
  const classes = useStyles()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const patientPermissions = useSelector(getCRUDByArea(PermissionArea.PATIENT))
  const reservedBlockPermissions = useSelector(
    getCRUDByArea(PermissionArea.RESERVED_BLOCK),
  )
  const soapPermissions = useSelector(getCRUDByArea(PermissionArea.SOAP))
  const appointmentPermissions = useSelector(
    getCRUDByArea(PermissionArea.EVENT_APPOINTMENT),
  )
  const EventType = useSelector(getEventType)
  const createdPatientId = useSelector(getCurrentPatientId)
  const createdClientId = useSelector(getCurrentClientId)
  const clientsError = useSelector(getClientsError)
  const clientsErrorStatus = useSelector(getClientsErrorStatus)
  const businessId = useSelector(getCurrentBusinessId)
  const isSaving = useSelector(getTimetableIsSaving)
  const isTimetableLoading = useSelector(getTimetableIsLoading)
  const isCreatingClient = useSelector(getClientIsCreating)
  const lastAppointmentId = useSelector(getTimetableLastAppointmentId)
  const isDeleting = useSelector(getTimetableIsDeleting)
  const validationError = useSelector(getTimetableValidationError)
  const multiValidationError = useSelector(getTimetableMultiValidationError)
  const timetableError = useSelector(getTimetableError)
  const appointmentCommunicationsConfiguration = useSelector(
    getCurrentBusinessAppointmentCommunicationsConfiguration,
  )
  const isOmnichannelBusiness = useSelector(getCurrentBusinessIsOmniChannel)
  const isPatientSharingEnabled = useSelector(
    getFeatureToggle(FeatureToggle.PATIENT_SHARING),
  )
  const isSendSMSViaPolarisEnabled = useSelector(
    getFeatureToggle(FeatureToggle.SEND_SMS_VIA_POLARIS),
  )
  const isAdditionalFieldsEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_DIALOG_ADDITIONAL_FIELDS),
  )
  const isAppointmentCancellationReasonEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_CANCELLATION_REASON),
  )
  const isAppointmentReservedEnabled = useSelector(
    getFeatureToggle(FeatureToggle.APPOINTMENT_RESERVED),
  )
  const isCurrentBusinessIsAppointmentCancellationReasonEnabled = useSelector(
    getCurrentBusinessIsAppointmentCancellationReasonEnabled,
  )
  const isDepositPayByLinkEnabled = useSelector(
    getFeatureToggle(FeatureToggle.DEPOSIT_PAY_BY_LINK),
  )

  const isNoShowCancellationEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY),
  )

  const isNoShowCancellationPenaltyCardOnFileAlertEnabled = useSelector(
    getFeatureToggle(
      FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_CARD_ON_FILE_ALERT,
    ),
  )

  const isNoShowPenaltyRefundEnabled = useSelector(
    getFeatureToggle(FeatureToggle.NO_SHOW_CANCELLATION_PENALTY_REFUND),
  )

  const cancelledStateId = useAppointmentStateId(AppointmentState.CANCELLED)
  const noShowStateId = useAppointmentStateId(AppointmentState.NO_SHOW)

  const { t } = useTranslation(['Common', 'TimeTable', 'Dialogs'])

  const [createNewClient, setCreateNewClient] = useState(false)
  const [createSoapOnSuccess, setCreateSoapOnSuccess] = useState(false)
  const [viewSoapOnSuccess, setViewSoapOnSuccess] = useState(false)
  const [viewSoapOrderOnSuccess, setViewSoapOrderOnSuccess] = useState(false)
  const [viewMessageClientOnSuccess, setViewMessageClientOnSuccess] =
    useState(false)
  const [
    openSendConfirmationEmailDialogOnSuccess,
    setOpenSendConfirmationEmailDialogOnSuccess,
  ] = useState(false)
  const [soapToView, setSoapToView] = useState<string>()
  const [hasChanges, setHasChanges] = useState(false)
  const [cancellationInfo, setCancellationInfo] = useState<
    CancellationInfo | {}
  >({})

  const [waiveLateCancellationFeeChecked, setWaiveLateCancellationFeeChecked] =
    useState<boolean | Nil>(null)
  const [cancellationInternalNotesValue, setCancellationInternalNotesValue] =
    useState('')
  const [recurringParam, setRecurringParam] = useState<string | undefined>(
    undefined,
  )

  const [openClientDuplicatesDialog] = useDialog(DialogNames.CLIENT_DUPLICATES)
  const [openRecurringEventDialog] = useDialog(DialogNames.RECURRING_EVENT)
  const [openMultiValidationErrorDialog] = useDialog(
    DialogNames.MULTI_VALIDATION_ERROR,
  )
  const [openAppointmentNewClientConfirmationDialog] = useDialog(
    DialogNames.APPOINTMENT_NEW_CLIENT_CONFIRMATION,
  )
  const [openSendConfirmationEmailDialog] = useDialog(
    DialogNames.EMAIL_APPOINTMENT_CONFIRMATION,
  )
  const [openEmailAppointmentDialog] = useDialog(DialogNames.EMAIL_APPOINTMENT)

  const [openAppointmentCancellationReasonDialog] = useDialog(
    DialogNames.APPOINTMENT_CANCELLATION_REASON,
  )

  const appointmentRef = useRef<AppointmentHandle>(null)
  const newClientRef = useRef<ClientHandle>(null)

  const isUserDataValid = () => newClientRef.current?.validate() ?? false
  const isAppointmentDataValid = () =>
    appointmentRef?.current?.validate() ?? false

  const isEdit = Boolean(appointment?.id)
  const soaps = appointment?.soaps || []
  const hasSoaps = soaps.length > 0
  const firstSoap = R.head(soaps)
  const isFinalizedSoap = getAppointmentHasFinalizedSoap(appointment)

  const AppointmentEvent =
    Utils.findConstantByName('Appointment', EventType) || {}
  const ScheduledEventStateId = Utils.findConstantIdByName(
    'Scheduled',
    AppointmentEvent.states,
  )
  const BusyEventType = Utils.findConstantByName('Busy', EventType)
  const BusySubtypes = BusyEventType.subTypes || []
  const BusyEventId = Utils.findConstantIdByName('Person', BusySubtypes)
  const ReservedEventId = Utils.findConstantIdByName('Reserved', BusySubtypes)

  const { fields, validate, reset, updateState } = useFields(
    [
      {
        name: 'clientId',
        validators: createNewClient ? [] : ['required'],
        initialValue: appointment?.client || clientIdProp || '',
        label: t('Common:CLIENT'),
      },
      {
        name: 'patientId',
        initialValue: appointment?.patient || patientIdProp || '',
      },
      {
        name: 'type',
        label: t('Common:TYPE_ONE'),
        validators: ['required'],
        initialValue:
          appointment?.businessAppointmentType?.id ||
          appointment?.type?.id ||
          appointmentTypeId ||
          '',
      },
      {
        name: 'state',
        initialValue:
          appointment?.state?.id || appointmentStateId || ScheduledEventStateId,
      },
    ],
    false,
  )

  const { clientId, patientId, type, state } = fields

  const [openConfirmAppointmentNoShowAlert] = useConfirmAppointmentNoShowAlert()

  const client = useSelector(getUser(clientId.value))
  const appointmentType = useSelector(getAppointmentType(type.value))
  const businessAppointmentType =
    appointment?.businessAppointmentType ?? appointmentType
  const AppointmentEventSubTypes = useEventType(
    EventTypeName.Appointment,
    'subTypes',
  )
  const OTCSaleId = Utils.findConstantIdByName(
    AppointmentTypeName.OTC_SALE,
    AppointmentEventSubTypes,
  )

  const isHighValue = isHighValueAppointment(
    businessAppointmentType?.name || '',
  )

  const showDepositAlert =
    isDepositPayByLinkEnabled && isOmnichannelBusiness && isHighValue

  const eventTypeId = appointmentType?.eventTypeId

  const isAppointmentTypeInListOfSupportedTypesForNoShowConsent =
    useGetIsNoShowConsentAppointmentType()

  const isOutsideCancellationWindow = getIsOutsideCancellationWindow(
    appointment?.scheduledStartDatetime,
    appointment?.scheduledEndDatetime,
  )

  const showNoShowCancellationPenaltyAlert =
    isNoShowCancellationEnabled &&
    isNoShowCancellationPenaltyCardOnFileAlertEnabled &&
    isOmnichannelBusiness &&
    isAppointmentTypeInListOfSupportedTypesForNoShowConsent(eventTypeId) &&
    isOutsideCancellationWindow

  const isOtcSaleTypeSelected = eventTypeId === OTCSaleId
  const typeName = appointmentType?.name || ''

  useFieldsChanged(() => {
    setHasChanges(true)
  }, fields)

  const isReserved = type.value === ReservedEventId
  const isBusy = type.value === BusyEventId
  const isBusyTypeSelected = isReserved || isBusy

  const hasUnsavedChanges = () => isFieldValuesChanged(fields) || hasChanges

  const entityName = R.cond([
    [R.equals(ReservedEventId), R.always(t('Common:RESERVED'))],
    [R.equals(BusyEventId), R.always(t('Common:BUSY'))],
    [R.T, R.always(t('Common:APPOINTMENT_ONE'))],
  ])(type.value)

  const originalBusinessId = isPatientSharingEnabled
    ? appointment?.businessId
    : null

  const createSoap = (appointmentId: string) => {
    navigate(
      addOriginalBusinessId(
        `/soap/create/${appointmentId}`,
        originalBusinessId,
      ),
    )
  }

  const isNoShowConsentAppointmentType = useGetIsNoShowConsentAppointmentType()

  const skipChewyCardPaymentMethodsQuery =
    !isNoShowCancellationPenaltyCardOnFileAlertEnabled ||
    !clientId.value ||
    appointmentType
      ? !isNoShowConsentAppointmentType(appointmentType?.eventTypeId)
      : !isNoShowConsentAppointmentType(appointment?.type?.id)

  const { data, loading: isChewyCardPaymentMethodsQueryLoading } =
    useChewyCardPaymentMethodsQuery({
      variables: { id: clientId.value },
      skip: skipChewyCardPaymentMethodsQuery,
    })

  const appointmentMissingPaymentMethodReasons = useSelector(
    getAppointmentMissingPaymentMethodReasons,
  )

  const cardPaymentMethods = getPrimaryCardPaymentMethod(
    data?.client.paymentMethods as CardPaymentMethod[],
  )

  const [
    paymentMethodOrMissingReasonValue,
    setPaymentMethodOrMissingReasonValue,
  ] = useState<CardPaymentMethodOrMissingReason | '' | null>('')

  const externalPaymentMethodId = appointment?.externalPaymentMethodId
  const missingPaymentMethodReasonId = appointment?.missingPaymentMethodReasonId

  const paymentMethod = externalPaymentMethodId
    ? getPaymentMethodById(externalPaymentMethodId, cardPaymentMethods)
    : null

  const missingPaymentMethodReason = missingPaymentMethodReasonId
    ? getMissingPaymentMethodReason(
        missingPaymentMethodReasonId,
        appointmentMissingPaymentMethodReasons,
      )
    : null

  const showNoShowPenaltyAlert =
    isNoShowPenaltyRefundEnabled &&
    isOmnichannelBusiness &&
    isEdit &&
    state.value === noShowStateId &&
    isAppointmentTypeInListOfSupportedTypesForNoShowConsent(eventTypeId)

  // For editing appointments, we render a default value of either
  // the payment method or the reason for the missing payment method
  // that was selected on appointment creation
  useEffect(() => {
    if (isEdit && isNilOrEmpty(paymentMethodOrMissingReasonValue)) {
      if (paymentMethod) {
        setPaymentMethodOrMissingReasonValue(paymentMethod)
      } else if (missingPaymentMethodReason) {
        setPaymentMethodOrMissingReasonValue(missingPaymentMethodReason)
      } // else leave it empty
    }
  }, [paymentMethod, missingPaymentMethodReason])

  const cardPaymentMethodsAndMissingPaymentMethodReasons: GroupedOption<
    '' | CardPaymentMethodOrMissingReason
  >[] = [
    {
      label: t('Common:CARDS_ON_FILE'),
      options: sortCardPaymentMethodsByPrimary(
        cardPaymentMethods,
      ) as CardPaymentMethodOrMissingReason[],
    },
    {
      label: t('Common:WHY_WAS_NO_CARD_SELECTED'),
      options:
        appointmentMissingPaymentMethodReasons as CardPaymentMethodOrMissingReason[],
    },
  ]

  const viewSoap = (soapId?: string) => {
    navigate(addOriginalBusinessId(`/soap/${soapId}`, originalBusinessId))
  }

  const viewSoapOrder = (soapId?: string) => {
    navigate(addOriginalBusinessId(`/soap/${soapId}/order`, originalBusinessId))
  }

  const openMessageClientDialog = (appointmentId: string | Nil) => {
    openEmailAppointmentDialog({ appointmentId, onViewConversation: onOk })
  }

  const onSaveComplete = () => {
    if (validationError) {
      return
    }

    if (!isEdit) {
      const easterEggExcludedAppointmentTypes: string[] = [
        AppointmentTypeName.EUTHANASIA,
        AppointmentTypeName.EXAM_URGENT,
        AppointmentTypeName.EXAM_SICK_VISIT,
      ]
      if (!easterEggExcludedAppointmentTypes.includes(typeName)) {
        dispatch(
          queueEasterEggEvent({
            actionType: EasterEggEvents.ADD_APPOINTMENT,
            actionSubtype: typeName,
          }),
        )
      }
    }

    const skipOnOk = viewMessageClientOnSuccess

    if (!skipOnOk) {
      onOk()
    }

    if (timetableError) {
      return
    }

    if (createNewClient) {
      openAppointmentNewClientConfirmationDialog()
    }

    const appointmentId = appointment?.id || lastAppointmentId

    if (createSoapOnSuccess && soapPermissions.create && appointmentId) {
      setCreateSoapOnSuccess(false)
      createSoap(appointmentId)
    }

    if (viewSoapOnSuccess) {
      setViewSoapOnSuccess(false)
      setSoapToView(undefined)
      viewSoap(soapToView)
    }

    if (viewSoapOrderOnSuccess) {
      setViewSoapOrderOnSuccess(false)
      viewSoapOrder(R.last(soaps)?.id)
    }

    if (openSendConfirmationEmailDialogOnSuccess) {
      setOpenSendConfirmationEmailDialogOnSuccess(false)
      openSendConfirmationEmailDialog({ appointmentId: lastAppointmentId })
    }

    if (viewMessageClientOnSuccess) {
      setViewMessageClientOnSuccess(false)
      openMessageClientDialog(appointmentId)
    }
  }

  const setProceedAfterSaved = useCloseAfterCreation(
    onSaveComplete,
    getTimetableIsSaving,
  )

  const addAppointment = (force?: boolean) => {
    if (!isAppointmentDataValid()) {
      return
    }

    const base = isBusyTypeSelected
      ? {}
      : {
          patient: createNewClient ? createdPatientId : patientId.value,
          client: createNewClient ? createdClientId : clientId.value,
        }

    const newAppointment = {
      state: state.value,
      business: businessId,
      ...base,
      ...appointmentRef?.current?.get(),
    }

    if (force) {
      newAppointment.bypassValidation = true
    }

    if (isEdit) {
      newAppointment.id = appointment?.id

      const action = isBusyTypeSelected ? editBusyTime : patchAppointment

      const patchedAppointment = isBusyTypeSelected
        ? newAppointment
        : getAppointmentPatch(newAppointment, appointment)
      const isNewlyCancelled =
        newAppointment?.state === cancelledStateId &&
        appointment?.state?.id !== cancelledStateId

      if (getIsRepeatedAppointment(newAppointment) || appointment?.parentId) {
        if (
          isAppointmentCancellationReasonEnabled &&
          isCurrentBusinessIsAppointmentCancellationReasonEnabled &&
          isNewlyCancelled
        ) {
          if (force) {
            setProceedAfterSaved()
            dispatch(
              action(
                {
                  ...patchedAppointment,
                  ...cancellationInfo,
                },
                recurringParam,
              ),
            )
          } else {
            openRecurringEventDialog({
              onProceed: (param: string | undefined) => {
                setRecurringParam(param)
                setProceedAfterSaved()
                dispatch(
                  action(
                    {
                      ...patchedAppointment,
                      ...cancellationInfo,
                    },
                    param,
                  ),
                )
              },
            })
          }
        } else if (force) {
          setProceedAfterSaved()
          dispatch(action(patchedAppointment, recurringParam))
        } else {
          openRecurringEventDialog({
            onProceed: (param: string | undefined) => {
              setRecurringParam(param)
              setProceedAfterSaved()
              dispatch(action(patchedAppointment, param))
            },
          })
        }
      } else if (
        isAppointmentCancellationReasonEnabled &&
        isCurrentBusinessIsAppointmentCancellationReasonEnabled &&
        isNewlyCancelled
      ) {
        setProceedAfterSaved()
        dispatch(
          action({
            ...patchedAppointment,
            ...cancellationInfo,
          }),
        )
      } else {
        setProceedAfterSaved()
        dispatch(action(patchedAppointment))
      }
    } else if (isBusyTypeSelected) {
      setProceedAfterSaved()
      dispatch(createBusyTime(newAppointment))
    } else {
      setProceedAfterSaved()
      dispatch(createAppointment(newAppointment))
    }
  }

  const onClientCreatedWithoutVerify = () => {
    if (!clientsError) {
      updateState({
        clientId: createdClientId,
        patientId: createdPatientId,
      })
      addAppointment()
    }
  }

  const setProceedAfterClientCreationWithoutVerify = useCloseAfterCreation(
    onClientCreatedWithoutVerify,
    getClientIsCreating,
  )

  const onPatientSelect = (stateClientId: string, statePatientId: string) => {
    updateState({
      clientId: stateClientId,
      patientId: statePatientId,
    })
    setCreateNewClient(false)
  }

  const onClientCreated = () => {
    if (clientsError) {
      if (clientsErrorStatus === 409) {
        const newClient = newClientRef?.current?.getClient()
        openClientDuplicatesDialog({
          email: newClient?.email,
          phone: newClient?.mobilePhone,
          onPatientSelect,
          expandPatients: true,
          onCreate: () => {
            setProceedAfterClientCreationWithoutVerify()
            if (newClient) {
              dispatch(createClient(newClient, false))
            }
          },
        })
      }
    } else {
      updateState({
        clientId: createdClientId,
        patientId: createdPatientId,
      })
      addAppointment()
    }
  }

  const setProceedAfterClientCreation = useCloseAfterCreation(
    onClientCreated,
    getClientIsCreating,
  )
  const setCloseAfterDeleteOn = useCloseAfterCreation(
    onOk,
    getTimetableIsDeleting,
  )

  useEffect(() => {
    reset()
    setHasChanges(false)
  }, [appointment])

  const hasClientAndPatient =
    Boolean(clientId.value && patientId.value) || createNewClient
  const showOpenSOAP =
    isEdit && !isBusyTypeSelected && hasSoaps && soapPermissions.read
  const canCreateSOAP =
    !isBusyTypeSelected &&
    !hasSoaps &&
    soapPermissions.create &&
    appointmentPermissions.create
  const showCreateSOAP = isEdit && canCreateSOAP
  const showCreateSOAPForOtc = isOtcSaleTypeSelected && !isEdit && canCreateSOAP
  const showDelete = isEdit && appointmentPermissions.delete && !hasSoaps
  const showClientDetails = hasClientAndPatient && !isBusyTypeSelected

  const onAddNewClientRequested = () => {
    setCreateNewClient(true)
  }

  const onSelected = (
    selectedClientId: string,
    selectedPatientId: string | Nil,
  ) => {
    updateState({ clientId: selectedClientId, patientId: selectedPatientId })
  }

  const save = () => {
    if (createNewClient) {
      const newClient = newClientRef?.current?.getClient()
      if (isUserDataValid() && isAppointmentDataValid() && newClient) {
        setProceedAfterClientCreation()
        dispatch(createClient(newClient, true))
      }
    } else {
      addAppointment()
    }
  }

  const saveAndClose = () => {
    if (
      !isEdit &&
      appointmentCommunicationsConfiguration?.newAppointmentAutoEmailEnabled &&
      !isBusyTypeSelected
    ) {
      setOpenSendConfirmationEmailDialogOnSuccess(true)
    }
    save()
  }

  const onViewSoapRequested = (soapId: string) => {
    setViewSoapOnSuccess(true)
    setSoapToView(soapId)
    if (hasChanges) {
      save()
    } else {
      setProceedAfterSaved()
    }
  }

  const onCreateSoapRequested = () => {
    setCreateSoapOnSuccess(true)
    save()
  }

  const onDeleteBusytimeRequested = () => {
    if (getIsRepeatedAppointment(appointment)) {
      openRecurringEventDialog({
        onProceed: (param: string | undefined) => {
          if (appointment?.id) {
            setCloseAfterDeleteOn()
            dispatch(deleteBusyTime(appointment.id, param))
          }
        },
      })
    } else if (appointment?.id) {
      setCloseAfterDeleteOn()
      dispatch(deleteBusyTime(appointment.id))
    }
  }

  const onAddChargesRequested = () => {
    setViewSoapOrderOnSuccess(true)
    save()
  }

  const onMessageClientRequested = () => {
    if (hasUnsavedChanges()) {
      setViewMessageClientOnSuccess(true)
      save()
    } else if (isOmnichannelBusiness && isSendSMSViaPolarisEnabled) {
      openSendConfirmationEmailDialog({ appointmentId: appointment?.id })
    } else {
      openMessageClientDialog(appointment?.id)
    }
  }

  const handleStateChange = (value: string) => {
    const isNewlyCancelled =
      value === cancelledStateId && state.initialValue !== cancelledStateId
    if (
      isAppointmentCancellationReasonEnabled &&
      isCurrentBusinessIsAppointmentCancellationReasonEnabled &&
      isNewlyCancelled
    ) {
      openAppointmentCancellationReasonDialog({
        onProceed: (
          cancellationReasonId: string,
          cancellationReason: string,
          internalNotes: string,
          waiveLateCancellationFee?: boolean,
        ) => {
          state.setValue(value)
          setCancellationInfo({
            cancellationReason,
            cancellationReasonId,
          })
          setCancellationInternalNotesValue(internalNotes)
          setWaiveLateCancellationFeeChecked(waiveLateCancellationFee)
        },
        showDepositAlert,
        isAppointmentWithin24Hours: isOutsideCancellationWindow,
        isNoShowConsentAppointmentType: isNoShowConsentAppointmentType(
          appointmentType?.eventTypeId,
        ),
        showNoShowCancellationPenaltyAlert,
      })
    } else if (
      isOmnichannelBusiness &&
      isDepositPayByLinkEnabled &&
      isHighValue &&
      value === noShowStateId
    ) {
      openConfirmAppointmentNoShowAlert({
        onConfirm: (proceed: boolean) => {
          if (proceed) {
            state.setValue(value)
          }
        },
        isHighValueAppointment: true,
      })
    } else if (
      isOmnichannelBusiness &&
      isNoShowPenaltyRefundEnabled &&
      isAppointmentTypeInListOfSupportedTypesForNoShowConsent(eventTypeId) &&
      value === noShowStateId
    ) {
      openConfirmAppointmentNoShowAlert({
        onConfirm: (proceed: boolean) => {
          if (proceed) {
            state.setValue(value)
          }
        },
        client,
        isHighValueAppointment: false,
      })
    } else {
      state.setValue(value)
    }
  }

  useEffect(() => {
    if (validationError) {
      openMultiValidationErrorDialog({
        errors: multiValidationError,
        proceedButtonActionName: t('Common:SCHEDULE_ACTION'),
        onBack: () => {
          dispatch(clearAppointmentValidationError())
          setCreateSoapOnSuccess(false)
          setViewSoapOnSuccess(false)
        },
        onProceed: () => addAppointment(true),
      })
    }
  }, [validationError])

  useImperativeHandle(ref, () => ({
    save,
    validate,
    hasUnsavedChanges,
  }))

  const onChangeClientAndPatientRequested = () => {
    updateState({
      clientId: '',
      patientId: '',
    })
  }

  const setTypeFromChildrenFields = (changedFields: FieldCache) => {
    const newType = changedFields?.find(R.propEq('name', 'type'))
    if (newType?.value) {
      type.setValue(newType.value)
    }
  }

  const handleChildrenFieldsChange = (changedFields: FieldCache) => {
    setTypeFromChildrenFields(changedFields)
    setHasChanges(true)
  }

  const isEditBookableReserved =
    isAppointmentReservedEnabled && isEdit && type.value === ReservedEventId
  const userHasReservedBlockPermissions =
    !isAppointmentReservedEnabled || reservedBlockPermissions.create
  const showTypeRadioButtons =
    !hasClientAndPatient &&
    !isEditBookableReserved &&
    userHasReservedBlockPermissions

  const headerPadding = isAppointmentReservedEnabled ? 2 : 3

  return (
    <Grid container item className={classes.root} direction="column">
      <Grid container item wrap="nowrap">
        <Grid
          container
          item
          className={classNames(classes.leftContainer, {
            [classes.leftContainerAppointmentMode]:
              hasClientAndPatient && !isBusyTypeSelected,
          })}
          direction="column"
          pt={headerPadding}
          xs={showClientDetails ? 7 : 12}
        >
          <Grid container item direction="column" px={headerPadding}>
            <Text pb={headerPadding} variant="h2">
              {isEdit
                ? isReserved &&
                  isAppointmentReservedEnabled &&
                  !userHasReservedBlockPermissions
                  ? t(
                      'TimeTable:ADD_APPOINTMENT_COMPONENT.RESERVED_BLOCK_DETAILS',
                    )
                  : t('TimeTable:ADD_APPOINTMENT_COMPONENT.EDIT_APPOINTMENT')
                : t('TimeTable:ADD_APPOINTMENT_COMPONENT.NEW_APPOINTMENT')}
            </Text>
            {showTypeRadioButtons && (
              <RadioGroup
                row
                aria-label="event-type"
                name="eventType"
                value={type.value}
                onChange={type.set}
              >
                <FormControlLabel
                  classes={{
                    label: classes.radioLabel,
                  }}
                  control={<Radio />}
                  label={t('Common:APPOINTMENT_ONE')}
                  value={isBusyTypeSelected ? '' : type.value}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={
                    <TextWithTooltip
                      tooltipText={
                        <>
                          {t('Common:EXAMPLE')}:
                          <Grid
                            item
                            className={classNames(
                              classes.card,
                              classes.reservedCard,
                            )}
                            my={0.5}
                            p={0.5}
                          >
                            <Text strong variant="lowAccent3">
                              {t('Common:RESERVED')}
                            </Text>
                            <Text variant="lowAccent3">
                              {t('TimeTable:URGENT_APPOINTMENTS_ONLY')}
                            </Text>
                          </Grid>
                        </>
                      }
                      variant="body"
                    >
                      {t('Common:RESERVED')}
                    </TextWithTooltip>
                  }
                  value={ReservedEventId}
                />
                <FormControlLabel
                  control={<Radio />}
                  label={
                    <TextWithTooltip
                      tooltipText={
                        <>
                          {t('Common:EXAMPLE')}:
                          <Grid
                            item
                            className={classNames(
                              classes.card,
                              classes.busyCard,
                            )}
                            my={0.5}
                            p={0.5}
                          >
                            <Text strong variant="lowAccent3">
                              {t('Common:UNAVAILABLE')}
                            </Text>
                            <Text variant="lowAccent3">{t('Common:BUSY')}</Text>
                          </Grid>
                        </>
                      }
                      variant="body"
                    >
                      {t('Common:BUSY')}
                    </TextWithTooltip>
                  }
                  value={BusyEventId}
                />
              </RadioGroup>
            )}
          </Grid>
          {!hasClientAndPatient && !isBusyTypeSelected && (
            <Grid item pb={3} px={3}>
              <ClientSelector
                actionBtnTitle={t('Common:ADD_TO_APPOINTMENT')}
                allowNoSubItemsSelection={false}
                direction="row"
                permissions={patientPermissions}
                resultsTitle={t('Common:ADD_PATIENT_TO_APPOINTMENT')}
                onAddNewClientRequested={onAddNewClientRequested}
                onSelected={onSelected}
              />
            </Grid>
          )}
          {(isBusyTypeSelected || hasClientAndPatient) && (
            <Grid
              container
              item
              className={
                isAdditionalFieldsEnabled
                  ? classes.contentWithAddedFields
                  : classes.content
              }
              direction="column"
            >
              {isBusyTypeSelected ? (
                <Busy
                  appointment={appointment}
                  appointmentTypeId={type.value}
                  personId={personIdProp}
                  ref={appointmentRef}
                  onFieldsChange={handleChildrenFieldsChange}
                />
              ) : (
                <Appointment
                  appointment={appointment}
                  appointmentState={state.value}
                  appointmentTypeId={type.value}
                  cancellationInfo={cancellationInfo as CancellationInfo}
                  cancellationInternalNotesValue={
                    cancellationInternalNotesValue
                  }
                  cardPaymentMethods={cardPaymentMethods}
                  cardPaymentMethodsAndMissingPaymentMethodReasons={
                    cardPaymentMethodsAndMissingPaymentMethodReasons
                  }
                  clientId={clientId.value}
                  isChewyCardPaymentMethodsQueryLoading={
                    isChewyCardPaymentMethodsQueryLoading
                  }
                  patientId={patientId.value}
                  paymentMethodOrMissingReasonValue={
                    paymentMethodOrMissingReasonValue ?? ''
                  }
                  personId={personIdProp}
                  ref={appointmentRef}
                  setCancellationInfo={setCancellationInfo}
                  setPaymentMethodOrMissingReasonValue={
                    setPaymentMethodOrMissingReasonValue
                  }
                  setWaiveLateCancellationFeeChecked={
                    setWaiveLateCancellationFeeChecked
                  }
                  waiveLateCancellationFeeChecked={
                    waiveLateCancellationFeeChecked
                  }
                  onAddChargesRequested={onAddChargesRequested}
                  onFieldsChange={handleChildrenFieldsChange}
                  onMessageClientRequested={onMessageClientRequested}
                  onOk={onOk}
                />
              )}
            </Grid>
          )}
        </Grid>
        {showClientDetails && (
          <Grid item xs={5}>
            {createNewClient ? (
              <ClientCreate showTitle ref={newClientRef} />
            ) : (
              <AppointmentClientAndPatientDetails
                appointment={appointment}
                clientId={clientId.value}
                patientId={patientId.value}
                onChangeRequested={
                  !appointmentPermissions.update || isFinalizedSoap || hasSoaps
                    ? undefined
                    : onChangeClientAndPatientRequested
                }
              />
            )}
          </Grid>
        )}
      </Grid>
      {(hasClientAndPatient || isBusyTypeSelected) &&
        (!isReserved || userHasReservedBlockPermissions) && (
          <Grid
            container
            item
            alignItems="center"
            className={classes.footer}
            pl={3}
            pr={2}
            py={2}
            wrap="nowrap"
          >
            <Grid container item>
              {showNoShowPenaltyAlert && (
                <Grid item>
                  <NoShowAlertText />
                </Grid>
              )}
              <Grid container item columnSpacing={2} wrap="nowrap">
                <Grid container item columnSpacing={2} wrap="nowrap">
                  <Grid item>
                    <ButtonWithLoader
                      className={classes.button}
                      disabled={
                        !appointmentPermissions.update ||
                        (isEdit && !hasChanges) ||
                        isTimetableLoading
                      }
                      loading={isSaving || isCreatingClient}
                      name="btnAddAppointment"
                      onClick={saveAndClose}
                    >
                      {isEdit
                        ? t('Common:SAVE_ACTION')
                        : t('Common:ADD_SOME_TYPE', {
                            type: entityName.toLowerCase(),
                          })}
                    </ButtonWithLoader>
                  </Grid>
                  {showOpenSOAP && (
                    <Grid item>
                      <OpenSoapButton
                        className={classes.button}
                        loading={isSaving || isCreatingClient}
                        soaps={appointment?.soaps || []}
                        onClick={onViewSoapRequested}
                      />
                    </Grid>
                  )}
                  {showCreateSOAP && (
                    <Grid item>
                      <ButtonWithLoader
                        className={classes.button}
                        loading={isSaving || isCreatingClient}
                        onClick={onCreateSoapRequested}
                      >
                        {t('Common:CREATE_SOAP')}
                      </ButtonWithLoader>
                    </Grid>
                  )}
                  {showCreateSOAPForOtc && (
                    <Grid item>
                      <ButtonWithLoader
                        className={classes.button}
                        loading={isSaving || isCreatingClient}
                        onClick={onCreateSoapRequested}
                      >
                        {t('Common:SAVE_AND_CREATE_SOAP')}
                      </ButtonWithLoader>
                    </Grid>
                  )}
                </Grid>
                <Grid container item xs columnSpacing={2} wrap="nowrap">
                  {isEdit && !isBusyTypeSelected && (
                    <Grid container item alignItems="center" wrap="nowrap">
                      <Grid item>
                        <AppointmentStatusSelect
                          disabled={!appointmentPermissions.update}
                          field={
                            {
                              value: state.value,
                              set: ({ target: { value } }) =>
                                handleStateChange(value),
                            } as Field
                          }
                        />
                      </Grid>
                      {isFinalizedSoap && (
                        <Grid item ml={2}>
                          <StateLabel
                            warning
                            className={classes.finalizedLabel}
                          >
                            {t('Common:FINALIZED')}
                            {firstSoap?.finalizedDate &&
                              `| ${DateUtils.formatDateWithHours(
                                firstSoap?.finalizedDate,
                                true,
                              )}`}
                            {firstSoap?.finalizedBy &&
                              ` ${t(
                                'Common:BY',
                              ).toLowerCase()} ${Utils.getPersonString(
                                firstSoap?.finalizedBy,
                              )}`}
                          </StateLabel>
                        </Grid>
                      )}
                    </Grid>
                  )}
                  <Grid
                    item
                    className={classes.actions}
                    data-testid="appointment-more-actions"
                  >
                    {isBusyTypeSelected ? (
                      showDelete ? (
                        <DeleteButton
                          loading={isDeleting}
                          onClick={onDeleteBusytimeRequested}
                        />
                      ) : null
                    ) : (
                      <ActionsButton
                        Icon={MoreHorizIcon}
                        actions={() => appointmentRef.current?.getActions()}
                        classes={{
                          icon: classes.icon,
                          iconButton: classes.iconButton,
                        }}
                      />
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        )}
    </Grid>
  )
})

export default AppointmentComponent
