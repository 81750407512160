import { ColorVariant } from './colors'

export const EstimateState = {
  DRAFT: 'Draft',
  DECLINED: 'Declined',
  APPROVED: 'Approved',
  EXPIRED_DRAFT: 'Expired-Draft',
  EXPIRED_APPROVED: 'Expired-Approved',
  EXPIRED_DECLINED: 'Expired-Declined',
}

export const RefundInvoiceState = {
  FAILED: 'Failed',
  PENDING: 'Pending',
  PARTIALLY_REFUNDED: 'Partially Refunded',
  REFUNDED: 'Refunded',
  VOIDED: 'Voided',
}

export const RetailOrderStates = {
  DRAFT: 'Draft',
  MOVED_TO_CHEWY_CART: 'Moved to Chewy cart',
  ORDER_PLACED: 'Order placed',
}

export const EstimateStateColor = {
  [EstimateState.DRAFT]: ColorVariant.IMPORTANT,
  [EstimateState.DECLINED]: ColorVariant.DISABLED,
  [EstimateState.APPROVED]: ColorVariant.SUCCESS,
  [EstimateState.EXPIRED_DRAFT]: ColorVariant.ERROR,
  [EstimateState.EXPIRED_APPROVED]: ColorVariant.ERROR,
  [EstimateState.EXPIRED_DECLINED]: ColorVariant.ERROR,
}

export const RefundInvoiceStateColor = {
  [RefundInvoiceState.FAILED]: ColorVariant.ERROR,
  [RefundInvoiceState.PENDING]: ColorVariant.IMPORTANT,
  [RefundInvoiceState.PARTIALLY_REFUNDED]: ColorVariant.IMPORTANT,
  [RefundInvoiceState.VOIDED]: ColorVariant.DISABLED,
}

export const RetailOrderStatesColor = {
  [RetailOrderStates.DRAFT]: 'warning',
  [RetailOrderStates.MOVED_TO_CHEWY_CART]: 'canceled',
  [RetailOrderStates.ORDER_PLACED]: 'normal',
}

export enum BillableInvoiceType {
  INVENTORY = 'INVENTORY',
  PRESCRIPTION = 'PRESCRIPTION',
  PROCEDURE = 'PROCEDURE',
  LAB_TEST = 'LAB_TEST',
}
