import React, { forwardRef, useEffect, useImperativeHandle } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'
import {
  LanguageUtils,
  NumberUtils,
  SpeciesConstant,
  Text,
  useFields,
} from '@pbt/pbt-ui-components'

import ActiveStateSwitch from '~/components/common/ActiveStateSwitch'
import PuiSwitch from '~/components/common/PuiSwitch'
import {
  BasePrice,
  DataHandleWithUnsavedChanges,
  Price,
  UnsavedPrice,
} from '~/types'
import { isFieldValuesChanged } from '~/utils'
import { useGetFormattedPriceUnit } from '~/utils/priceUtils'

export interface ReadOnlyPriceProps {
  availableSpecies: SpeciesConstant[]
  className?: string
  price: BasePrice
}

export interface ReadOnlyPriceHandle
  extends DataHandleWithUnsavedChanges<UnsavedPrice> {}

const ReadOnlyPrice = forwardRef<ReadOnlyPriceHandle, ReadOnlyPriceProps>(
  function ReadOnlyPrice({ availableSpecies, price, className }, ref) {
    const { t } = useTranslation(['Common'])

    const { fields, validate, reset } = useFields(
      [
        {
          name: 'discountAllowed',
          label: t('Common:ALLOW_DISCOUNT'),
          type: 'toggle',
          initialValue: price.discountAllowed,
        },
        {
          name: 'taxable',
          label: t('Common:TAXABLE'),
          type: 'toggle',
          initialValue: price.taxable,
        },
        {
          name: 'active',
          type: 'toggle',
          initialValue: price.active,
        },
      ],
      false,
    )
    const { discountAllowed, taxable, active } = fields

    const getPrice = () => {
      const newPrice: UnsavedPrice = {
        ...price,
        discountAllowed: discountAllowed.value,
        taxable: taxable.value,
        active: active.value,
      }

      return newPrice
    }

    useImperativeHandle(ref, () => ({
      get: getPrice,
      validate,
      hasUnsavedChanges: () => isFieldValuesChanged(fields),
    }))

    useEffect(() => {
      reset()
    }, [price])

    const priceInfo = [
      { label: t('Common:PRICE_NAME'), value: price.name },
      {
        label: t('Common:SPECIES'),
        value: LanguageUtils.getConstantTranslatedName(
          price.speciesId,
          availableSpecies,
        ),
      },
      {
        label: t('Common:PRICE'),
        value: NumberUtils.formatMoney(price.price ?? 0),
      },
      {
        label: t('Common:MINIMUM_CHARGE'),
        value: NumberUtils.formatMoney(price.minCharge ?? 0),
      },
      {
        label: t('Common:UNITS'),
        value: useGetFormattedPriceUnit(price as Price),
      },
      { label: t('Common:CODE'), value: price.originalCode },
    ]

    return (
      <>
        <Grid
          item
          borderBottom={(theme) => theme.constants.tabBorder}
          pl={2}
          sx={{ display: { xs: 'block', md: 'none' } }}
        >
          <ActiveStateSwitch field={active} />
        </Grid>
        <Grid container className={className} direction="column" wrap="nowrap">
          {priceInfo.map(({ label, value }) => (
            <Grid
              item
              borderBottom={(theme) => theme.constants.tabBorder}
              display="flex"
              flexDirection="row"
              key={label}
              p={1}
            >
              <Text
                flex={1}
                fontWeight={(theme) => theme.typography.fontWeightBold}
                variant="body2"
              >
                {label}
              </Text>
              <Text flex={2} variant="body2">
                {value}
              </Text>
            </Grid>
          ))}
          <Grid container item direction="column" mt={{ xs: 2, md: 4 }}>
            <Grid item p={0}>
              <PuiSwitch
                field={discountAllowed}
                label={discountAllowed.label}
              />
            </Grid>
            <Grid
              container
              item
              alignItems="flex-end"
              columnSpacing={1}
              wrap="nowrap"
            >
              <Grid item>
                <PuiSwitch field={taxable} label={taxable.label} />
              </Grid>
            </Grid>
            <Grid item sx={{ display: { xs: 'none', md: 'block' } }}>
              <ActiveStateSwitch field={active} />
            </Grid>
          </Grid>
        </Grid>
      </>
    )
  },
)

export default ReadOnlyPrice
