/* eslint-disable react/no-multi-comp */
import React from 'react'
import { useTranslation } from 'react-i18next'
import { Box, styled } from '@mui/material'
import * as R from 'ramda'
import { Nil, PuiTooltip, Text } from '@pbt/pbt-ui-components'
import { ChewySubmark, Info } from '@pbt/pbt-ui-components/src/icons'

import {
  ChargesSection,
  Invoice as GraphqlInvoice,
  RefundInvoice,
} from '~/api/graphql/generated/types'
import Typography from '~/components/elements/Typography/Typography'
import DialogNames from '~/constants/DialogNames'
import i18nPortal from '~/locales/i18n'
import { useGetChewyOrderState } from '~/store/hooks/retailOrderItems'
import { Invoice } from '~/types'
import { UIRoliSection, useGetInvoiceSectionsWithLimit } from '~/utils/finance'
import useDialog from '~/utils/useDialog'

import { MultipleLinesCell } from './MultipleLinesCell'

const StyledInfoIcon = styled(Info)(({ theme }) => ({
  color: theme.colors.link,
  cursor: 'pointer',
  margin: theme.spacing(1),
}))

interface InvoiceTableEventTypeCellProps {
  hideRetailOrder?: boolean
  invoice: Invoice | GraphqlInvoice | RefundInvoice
  multipleRowsLimit?: number
}

const otcLabel = i18nPortal.t<string>(
  'Abbreviations:ACRONYMS.OVER_THE_COUNTER.LABEL_ABBREVIATION',
)

const useOpenAppointment = () => {
  const [openAppointmentDialog] = useDialog(DialogNames.EVENT)

  const openAppointment = (eventId: string | Nil) => {
    openAppointmentDialog({
      appointmentId: eventId,
      isAppointmentType: true,
    })
  }

  return openAppointment
}

const InvoiceLineItem = (section: ChargesSection) => {
  const { event } = section
  const openAppointment = useOpenAppointment()
  return event ? (
    <Text link variant="body2" onClick={() => openAppointment(event.id)}>
      {event.type?.name || ''}
    </Text>
  ) : (
    <Text variant="body2">{otcLabel}</Text>
  )
}

const RetailOrderLineItem = (section: UIRoliSection) => {
  const { t } = useTranslation(['Invoices'])
  const getChewyOrderState = useGetChewyOrderState()
  const hasState = 'state' in section && section.state

  if (!hasState) {
    return <></>
  }

  const { isMovedToChewyCart, isOrderPlaced } = getChewyOrderState(
    section.state?.id,
  )
  const chewyTooltip = isOrderPlaced
    ? t('Invoices:CHEWY_ITEMS.ORDER_PLACED_INFORMATION')
    : isMovedToChewyCart
      ? t('Invoices:CHEWY_ITEMS.CHEWY_CART_INFORMATION')
      : t('Invoices:CHEWY_ITEMS.DRAFT_INFORMATION_ALTERNATIVE')

  return (
    <Box
      alignItems="center"
      display="flex"
      gap={0.5}
      justifyContent="space-around"
      width="100%"
    >
      <ChewySubmark />
      <Typography.Paragraph>
        {t('Common:CHEWY_COM_ORDER')}
        {isOrderPlaced ? `: ${section.orderId}` : ''}
      </Typography.Paragraph>
      <PuiTooltip sx={{ ml: 'auto' }} tooltipText={chewyTooltip}>
        <StyledInfoIcon />
      </PuiTooltip>
    </Box>
  )
}

export const InvoiceTableEventTypeCell = ({
  hideRetailOrder = false,
  invoice,
  multipleRowsLimit,
}: InvoiceTableEventTypeCellProps) => {
  const sections = useGetInvoiceSectionsWithLimit(invoice, multipleRowsLimit)

  const openAppointment = useOpenAppointment()

  if (sections && !R.isEmpty(sections)) {
    return (
      <MultipleLinesCell
        InvoiceLineItem={InvoiceLineItem}
        RetailOrderLineItem={RetailOrderLineItem}
        hideRetailOrder={hideRetailOrder}
        sections={sections}
      />
    )
  }

  if ('event' in invoice && invoice.event) {
    const invoiceEvent = invoice.event

    return (
      <Text
        link
        variant="body2"
        onClick={() => openAppointment(invoiceEvent?.id)}
      >
        {invoiceEvent?.type?.name || otcLabel}
      </Text>
    )
  }

  return null
}
